var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "suggestion-box-task",
      on: {
        mousedown: function($event) {
          $event.preventDefault() /* prevent blur */
        },
        click: function($event) {
          return _vm.$emit("click", _vm.idx)
        },
        mouseenter: _vm.handleMouseEnter,
        mouseleave: _vm.handleMouseLeave
      }
    },
    [
      _c("div", { class: _vm.rootClasses }, [
        _c(
          "div",
          { staticClass: "category-bar" },
          _vm._l(_vm.category, function(c, idx) {
            return _c(
              "div",
              {
                key: _vm.categoryPartKey(_vm.category, idx),
                staticClass: "category-name"
              },
              [_vm._v(" " + _vm._s(c) + " ")]
            )
          }),
          0
        ),
        _c("div", { staticClass: "task-main" }, [
          _c("div", { staticClass: "task-name" }, [_vm._v(_vm._s(_vm.name))]),
          _c("div", { staticClass: "task-priority" }, [
            _vm._v(_vm._s(_vm.$t("task_priority_" + _vm.priority)))
          ])
        ]),
        _c(
          "div",
          { staticClass: "tags" },
          _vm._l(_vm.tags, function(t, idx) {
            return _c(
              "div",
              { key: _vm.tagKey(_vm.tags, idx), staticClass: "tag" },
              [_vm._v(" " + _vm._s(t) + " ")]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }