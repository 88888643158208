function isArrayOrNully(a) {
  return a === null || a === undefined || Array.isArray(a);
}

export function arrayEquals(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  if (a.length !== b.length) return false;
  for (let i=0; i<a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

/**
 * compares two arrays element by element, as for string-like sorting
 */
export function arrayCompare(a, b) {
  if (!isArrayOrNully(a) || !isArrayOrNully(b)) {
    throw new Error(`Cannot compare ${a} and ${b} as arrays`);
  }
  if (a === b) return 0; // same object, both null, or both undefined
  if (!a && !b) return 0; // one null, other undefined
  if (!a) return -1;
  if (!b) return 1;
  const maxLen = Math.max(a.length, b.length);
  for (let i=0; i<maxLen; i++) {
    if (i >= a.length) return -1;
    if (i >= b.length) return 1;
    if (a[i] < b[i]) return -1;
    if (a[i] > b[i]) return 1;
  }
  return 0;
}

/**
 * Compares two objects basing on array returned by mapper function.
 * @param a first object to compare
 * @param b second object to compare
 * @param mapper function that taking the object (a or b) returns an array
 */
export function compareByArray(a, b, mapper) {
  return arrayCompare(mapper(a), mapper(b));
}

export function arrayStartsWith(arr, prefix) {
  if (prefix.length > arr.length) return false;
  for (let i=0; i<prefix.length; i++) {
    if (arr[i] !== prefix[i]) return false;
  }
  return true;
}
