var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tasks
    ? _c("task-list-base", {
        attrs: { tasks: _vm.tasks, includeCompleted: _vm.includeCompleted },
        on: {
          update: _vm.handleUpdate,
          edit: _vm.handleEdit,
          delete: _vm.handleDelete
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(slot) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "task-button",
                      on: {
                        click: function($event) {
                          return _vm.handleEdit(slot.task)
                        }
                      }
                    },
                    [_c("font-awesome-icon", { attrs: { icon: "edit" } })],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "task-button",
                      on: {
                        click: function($event) {
                          return _vm.handleAddPlanned(slot.task)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "calendar-plus"] }
                      })
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "task-button delete",
                      on: {
                        click: function($event) {
                          return _vm.handleDelete(slot.task)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "trash-alt"] }
                      })
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "task-button done",
                      on: {
                        click: function($event) {
                          return _vm.handleDone(slot.task)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "check-square"] }
                      })
                    ],
                    1
                  ),
                  _vm.isRunning(slot.task)
                    ? _c(
                        "button",
                        {
                          staticClass: "task-button",
                          on: {
                            click: function($event) {
                              return _vm.handleStop(slot.task)
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "stop"] }
                          })
                        ],
                        1
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "task-button",
                          on: {
                            click: function($event) {
                              return _vm.handleStart(slot.task)
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "play"] }
                          })
                        ],
                        1
                      )
                ]
              }
            }
          ],
          null,
          false,
          1368376777
        )
      })
    : _c("img", { attrs: { src: _vm.loader, alt: "$t('loading')" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }