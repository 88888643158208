var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: _vm.containerClasses }, [
    _vm.showCategory
      ? _c("div", { staticClass: "category-bar" }, [
          _c(
            "div",
            { staticClass: "category-bar-inner" },
            _vm._l(_vm.task.category, function(c, idx) {
              return _c(
                "div",
                {
                  key: _vm.categoryPartKey(_vm.task.category, idx),
                  staticClass: "category-name"
                },
                [_vm._v(" " + _vm._s(c) + " ")]
              )
            }),
            0
          )
        ])
      : _vm._e(),
    _c("div", { class: _vm.taskClasses }, [
      _c("div", { staticClass: "own-content" }, [
        _c(
          "div",
          { staticClass: "flex clickable", on: { click: _vm.handleClick } },
          [
            _c("div", { staticClass: "task-name" }, [
              _vm._v(_vm._s(_vm.taskName))
            ]),
            _c("div", { staticClass: "task-priority" }, [
              _vm._v(_vm._s(_vm.priorityStr))
            ])
          ]
        )
      ]),
      _vm.$slots.default
        ? _c("div", { staticClass: "extra" }, [_vm._t("default")], 2)
        : _vm._e()
    ]),
    _vm.childrenOpen
      ? _c(
          "div",
          { staticClass: "task-children" },
          [
            _c("time-entry-list", {
              attrs: { timeEntries: _vm.task.timeEntries },
              on: { edit: _vm.handleEdit, delete: _vm.handleDelete }
            }),
            _c("planned-task-entry-list", {
              attrs: { plannedTasks: _vm.task.plannedTasks },
              on: {
                update: _vm.handleUpdate,
                edit: _vm.handleEdit,
                delete: _vm.handleDelete
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }