import TaskDAO from '@/core/models/dao/TaskDAO';
import TimeEntryDAO from '@/core/models/dao/TimeEntryDAO';
import PlannedTaskDAO from '@/core/models/dao/PlannedTaskDAO';

import Task from '@/core/models/Task';
import TimeEntry from '@/core/models/TimeEntry';
import PlannedTask from '@/core/models/PlannedTask';

export default {
  /**
   * Creates correct dao from raw object
   */
  makeDAO(data) {
    let dao;
    switch (data.is) {
      case 'task':
        dao = new TaskDAO(data);
        break;
      case 'planned_task':
        dao = new PlannedTaskDAO(data);
        break;
      case 'time_entry':
        dao = new TimeEntryDAO(data);
        break;
      default:
        throw new Error(`Unknown DAO type: ${data.is}`);
    }
    if (data._deleted) dao._deleted = data._deleted;
    return dao;
  },

  makeSimpleModel(dao) {
    let model;
    switch (dao.is) {
      case 'task':
        model = new Task(dao);
        break;
      case 'time_entry':
      case 'planned_task':
        throw new Error('Planned task and time entry cannot be made using makeSimpleModel');
      default:
        throw new Error(`Unknown DAO type: ${dao.is}`);
    }
    if (dao._deleted) model._deleted = dao._deleted;
    return model;
  },

  makeChildModel(dao, task) {
    let model;
    switch (dao.is) {
      case 'task':
        throw new Error('Task cannot be child of another task');
      case 'time_entry':
        model = new TimeEntry(task, dao);
        break;
      case 'planned_task':
        model = new PlannedTask(task, dao);
        break;
      default:
        throw new Error(`Unknown DAO type: ${dao.is}`);
    }
    if (dao._deleted) model._deleted = dao._deleted;
    return model;
  },
};
