<template>
  <div
    class="modal-container"
    @click.self="handleNo"
    @keyup.escape="handleNo"
  >
    <div class="modal-window">
      <p class="message">{{ message }}</p>
      <button
        id="confirm-button-yes"
        class="yes"
        @click="handleYes"
      >{{ yesText || defaultYesText }}</button>
      <button
        id="confirm-button-no"
        class="no"
        @click="handleNo"
      >{{ noText || defaultNoText }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'confirmation-popup',
  props: {
    message: {
      type: String,
      required: true,
    },
    yesText: {
      type: String,
    },
    noText: {
      type: String,
    },
    context: {
      required: true,
    },
  },
  data() {
    return {
      // Cannot use default prop value, since there's no `this` available there
      defaultYesText: this.$t('yes'),
      defaultNoText: this.$t('no'),
    };
  },
  mounted() {
    document.getElementById('confirm-button-yes').focus();
  },
  methods: {
    handleYes() {
      this.$emit('yes', this.context);
    },
    handleNo() {
      this.$emit('no', this.context);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_colors.scss';
@import '@/scss/_fonts.scss';
@import '@/scss/_modals.scss';

$color-border: scale-color($color-error,  $lightness: -50%, $saturation: -30%);

.modal-container {
  @include modal-container($color-error);
}

.modal-window {
  @include modal-window(gray($color-error), $color-border);
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-items: stretch;

  .message {
    color: scale-color($color-error, $lightness: -90%);
    font-size: 30px;
    margin: 0 0 20px 0;
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  .yes, .no {
    @include modal-button($color-border);
  }

  .yes {
    background-color: $color-error;
    color: $color-accent;
  }

  .no {
    background-color: scale-color($color-light-alt, $lightness: -30%, $saturation: +10%);
    color: scale-color($color-accent, $lightness: -70%);
  }
}
</style>
