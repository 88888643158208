let counter = 0;

export default {
  beforeCreate() {
    this.guid = counter.toString(36);
    counter++;
  },
  methods: {
    id(prefix) {
      return `${prefix}-${this.guid}`;
    },
  },
};
