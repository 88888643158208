var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { attrs: { id: "notifs" } },
      [
        _c("toast-notification", { attrs: { toasts: _vm.toasts } }),
        _c("sync-indicator")
      ],
      1
    ),
    _c(
      "main",
      [
        _c("div", [
          _c(
            "nav",
            { attrs: { id: "planning-day" } },
            [
              _c(
                "button",
                {
                  staticClass: "datepicker-button prev",
                  attrs: { disabled: !_vm.prevDayEnabled },
                  on: { click: _vm.prevDay }
                },
                [_c("font-awesome-icon", { attrs: { icon: "caret-left" } })],
                1
              ),
              _c("vue-datepicker", {
                attrs: {
                  type: "date",
                  clearable: false,
                  "disabled-date": _vm.isDateDisabled,
                  value: _vm.date
                },
                on: { change: _vm.changeDate }
              }),
              _c(
                "button",
                {
                  staticClass: "datepicker-button next",
                  on: { click: _vm.nextDay }
                },
                [_c("font-awesome-icon", { attrs: { icon: "caret-right" } })],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.term,
                    expression: "term"
                  }
                ],
                ref: "searchInput",
                attrs: {
                  placeholder: "Search or create task...",
                  id: "search-box"
                },
                domProps: { value: _vm.term },
                on: {
                  keydown: [
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.onDown($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.onUp($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.onEnter($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.onSelect($event)
                    }
                  ],
                  focus: _vm.showSuggestions,
                  blur: _vm.hideSuggestions,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.term = $event.target.value
                  }
                }
              }),
              _c("suggestion-box", {
                ref: "suggestionBox",
                attrs: { search: _vm.term, show: _vm.suggestions },
                on: { select: _vm.handleSelected, addTask: _vm.handleAddTask }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "planned-tasks" },
            [
              _c("task-list-planned", {
                attrs: { tasks: _vm.plannedTasks, loader: "/img/loader.gif" },
                on: {
                  edit: _vm.handleEdit,
                  delete: _vm.handleDelete,
                  update: _vm.handleSave,
                  done: _vm.handleMarkDone
                }
              })
            ],
            1
          )
        ]),
        _vm.question
          ? _c("confirmation-popup", {
              attrs: {
                context: _vm.confirmationContext,
                message: _vm.question
              },
              on: { no: _vm.handleNo, yes: _vm.handleYes }
            })
          : _vm._e(),
        _vm.editedItem
          ? _c("task-editor", {
              attrs: { task: _vm.editedItem },
              on: { cancel: _vm.handleCancelSave, save: _vm.handleSave }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }