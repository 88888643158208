<template>
  <vue-datepicker
    type="date"
    range
    :clearable="false"
    v-model="dates">
    <template v-slot:header="{ emit }">
      <div class="datepicker-buttons">
        <button class="mx-btn mx-btn-text" @click="today(emit)">
          {{ $t('today') }}
        </button>
        <button class="mx-btn mx-btn-text" @click="thisWeek(emit)">
          {{ $t('this_week') }}
        </button>
        <button class="mx-btn mx-btn-text" @click="thisMonth(emit)">
          {{ $t('this_month') }}
        </button>
        <button class="mx-btn mx-btn-text" @click="last7(emit)">
          {{ $t('last_7_days') }}
        </button>
        <button class="mx-btn mx-btn-text" @click="yesterday(emit)">
          {{ $t('yesterday') }}
        </button>
        <button class="mx-btn mx-btn-text" @click="lastWeek(emit)">
          {{ $t('last_week') }}
        </button>
        <button class="mx-btn mx-btn-text" @click="lastMonth(emit)">
          {{ $t('last_month') }}
        </button>
        <button class="mx-btn mx-btn-text" @click="last28(emit)">
          {{ $t('last_4_weeks') }}
        </button>
      </div>
    </template>
  </vue-datepicker>
</template>

<script>
import {
  DAY,
  WEEK,
  today,
  yesterday,
  startOfWeek,
  startOfMonth,
} from '@/core/utils/dateUtils';

export default {
  props: {
    value: {
      type: Array,
      required: true,
      validator(v) {
        return v.length === 2 && v[0] instanceof Date && v[1] instanceof Date;
      },
    },
  },
  methods: {
    today(emit) {
      const start = today();
      const end = today();
      emit([start, end]);
    },
    yesterday(emit) {
      const start = new Date(yesterday());
      const end = new Date(yesterday());
      emit([start, end]);
    },
    thisWeek(emit) {
      const start = startOfWeek(new Date());
      const end = today();
      emit([start, end]);
    },
    lastWeek(emit) {
      const thisWeekStart = startOfWeek(new Date());
      const start = new Date(thisWeekStart.getTime() - WEEK);
      const end = new Date(thisWeekStart.getTime() - DAY);
      emit([start, end]);
    },
    thisMonth(emit) {
      const start = startOfMonth(new Date());
      const end = today();
      emit([start, end]);
    },
    lastMonth(emit) {
      const end = new Date(startOfMonth(new Date()).getTime() - DAY);
      const start = startOfMonth(new Date(end.getTime() - 1));
      emit([start, end]);
    },
    last7(emit) {
      const end = today();
      const start = new Date(end.getTime() - DAY*6); // "today" is the 7th day
      emit([start, end]);
    },
    last28(emit) {
      const end = today();
      const start = new Date(end.getTime() - DAY*27); // see above
      emit([start, end]);
    },
  },
  computed: {
    dates: {
      get() {
        // end date is one day more than what should be visible since it's until end of day
        return [this.value[0], new Date(this.value[1] - DAY)];
      },
      set(v) {
        const start = v[0];
        // end date should be until "end of day" so until one day later
        const end = new Date(v[1].getTime() + DAY);
        this.$emit('input', [start, end]);
      },
    },
  },
};
</script>

<style scoped>
.datepicker-buttons {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
</style>
