var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quick-add-wrapper" },
    [
      _c(
        "form",
        {
          staticClass: "quick-add-task",
          attrs: { id: _vm.id("quick-add-task") },
          on: {
            keydown: _vm.formKeyDown,
            keyup: _vm.formKeyUp,
            submit: function($event) {
              $event.preventDefault()
              return (function() {})($event)
            }
          }
        },
        [
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }
              ],
              staticClass: "task-name-input",
              attrs: {
                autocomplete: "off",
                id: _vm.id("new-task-name"),
                placeholder: _vm.$t("task_name")
              },
              domProps: { value: _vm.name },
              on: {
                focus: _vm.showSuggestions,
                blur: _vm.hideSuggestions,
                keyup: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown"
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.onDown($event)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp"
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.onUp($event)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onEnter($event)
                  }
                ],
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.name = $event.target.value
                }
              }
            })
          ]),
          _c("tag-selector", {
            attrs: {
              id: _vm.id("new-task-category"),
              placeholder: _vm.$t("task_category"),
              suggest: _vm.suggestCategory
            },
            model: {
              value: _vm.category,
              callback: function($$v) {
                _vm.category = $$v
              },
              expression: "category"
            }
          }),
          _c("div", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.priority,
                    expression: "priority"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.priority = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(new Set(Object.values(_vm.priorities)), function(p) {
                return _c(
                  "option",
                  { key: "option-priority-" + p, domProps: { value: p } },
                  [_vm._v(" " + _vm._s(_vm.$t("task_priority_" + p)) + " ")]
                )
              }),
              0
            )
          ]),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "task-button square30",
                attrs: { type: "button", id: _vm.id("add-task") },
                on: { click: _vm.defaultAction }
              },
              [
                _vm.forceStart || (_vm.autostart && !_vm.forceAdd)
                  ? _c("font-awesome-icon", { attrs: { icon: "play" } })
                  : _c("font-awesome-icon", { attrs: { icon: "plus-square" } })
              ],
              1
            )
          ]),
          _c("label", { attrs: { for: "auto-start-checkbox" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.autostart,
                  expression: "autostart"
                }
              ],
              attrs: {
                type: "checkbox",
                name: "auto-start-task",
                id: "auto-start-checkbox"
              },
              domProps: {
                checked: Array.isArray(_vm.autostart)
                  ? _vm._i(_vm.autostart, null) > -1
                  : _vm.autostart
              },
              on: {
                change: function($event) {
                  var $$a = _vm.autostart,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.autostart = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.autostart = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.autostart = $$c
                  }
                }
              }
            }),
            _vm._v(" " + _vm._s(_vm.$t("auto_start_checkbox")) + " ")
          ])
        ],
        1
      ),
      _c("suggestion-box", {
        ref: "suggestionBox",
        attrs: { search: _vm.name, show: _vm.suggestions },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return (function() {})($event)
          },
          select: _vm.startExisting,
          addTask: _vm.handleAddTask
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }