const priority = {};

function definePriority(name, value) {
  Object.defineProperty(priority, name.toUpperCase(), {
    enumerable: true,
    configurable: false,
    writable: false,
    value,
  });
}

definePriority('critical', 1);
definePriority('high', 2);
definePriority('medium', 3);
definePriority('normal', 3);
definePriority('default', 3);
definePriority('low', 4);
definePriority('idle', 5);

export {
  priority, // eslint-disable-line import/prefer-default-export
};
