<template>
  <div class="suggestion-box-wrapper">
    <ul class="suggestion-box" v-show="show && tasks">
      <suggestion-box-item
        v-for="(task, idx) in tasks"
        :key="task._id"
        v-bind=task
        :selected="isSelected(idx)"
        :idx="idx"
        @click="handleClick"
        @mouseSelect="handleMouseSelect"
        @mouseDeselect="handleMouseDeselect"
        />
      <li
        class="suggestion-add-task"
        @mousedown.prevent="/* prevent blur */"
        @click="createNew"
      >
      {{ $t('new_task') }}
      </li>
    </ul>
  </div>
</template>

<script>
import SuggestionBoxItem from '@/components/SuggestionBoxItem.vue';

export default {
  components: {
    SuggestionBoxItem,
  },
  props: {
    search: String,
    show: Boolean,
  },
  data() {
    return {
      tasks: null,
      selectedTaskIdx: null,
      debunceTs: 0,
    };
  },
  methods: {
    next() {
      if (this.selectedTaskIdx === null) {
        this.selectedTaskIdx = 0;
      } else {
        this.selectedTaskIdx++;
      }
      if (this.selectedTaskIdx >= this.tasks.length) {
        this.selectedTaskIdx = null;
      }
    },
    prev() {
      if (this.selectedTaskIdx === null) {
        this.selectedTaskIdx = this.tasks.length-1;
      } else {
        this.selectedTaskIdx--;
      }
      if (this.selectedTaskIdx < 0) {
        this.selectedTaskIdx = null;
      }
    },
    select() {
      if (this.selectedTaskIdx !== null) {
        this.$emit('select', this.tasks[this.selectedTaskIdx]);
      }
      this.selectedTaskIdx = null;
    },
    isSelected(idx) {
      return idx === this.selectedTaskIdx;
    },
    handleClick(idx) {
      this.selectedTaskIdx = idx;
      this.select();
    },
    createNew() {
      this.selectedTaskIdx = null;
      this.$emit('addTask', task => this.$emit('select', task));
    },
    handleMouseSelect(idx) {
      this.selectedTaskIdx = idx;
    },
    handleMouseDeselect(idx) {
      if (this.selectedTaskIdx === idx) {
        this.selectedTaskIdx = null;
      }
    },
  },
  watch: {
    search(term) {
      if (!term) {
        this.tasks = null;
        return;
      }
      const now = Date.now();
      const debounce = now - this.debounceTs;
      this.debounceTs = now;
      if (debounce < 500) return;
      this.$manager.find({ name: term })
        .then((tt) => { this.tasks = tt.filter(t => !t.done); })
        .catch(err => console.error(err));
    },
  },
};
</script>

<style>
.suggestion-box-wrapper {
  position: relative;
  right: 0;
  top: 0;
  margin: 0;
  padding: 0;
}
.suggestion-box {
  position: absolute;
  list-style: none;
  border: 1px solid black;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
}
.suggestion-add-task {
  width: 100%;
  margin: 0;
  padding: 1em 0.5em;
}
.suggestion-add-task:hover {
  background-color: #6080ff;
}
</style>
