var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.tasks
    ? _c(
        "g",
        {
          attrs: {
            transform: "translate(" + _vm.radius + ", " + _vm.radius + ")"
          }
        },
        [
          _vm._l(_vm.chartData, function(datum) {
            return _c("path", {
              key: _vm.keyFor(datum, "path"),
              attrs: { fill: datum.fill, d: datum.d },
              on: {
                mouseenter: function($event) {
                  return _vm.select(datum)
                },
                mouseleave: function($event) {
                  return _vm.deselect(datum)
                },
                click: function($event) {
                  return _vm.toggle(datum)
                }
              }
            })
          }),
          _vm.selected
            ? _c("path", {
                staticClass: "selected",
                attrs: { fill: _vm.selectedDatum.fill, d: _vm.selectedDatum.d }
              })
            : _vm._e(),
          _vm.selected
            ? _c(
                "stat-chart-label",
                _vm._b({}, "stat-chart-label", _vm.selectedDatum, false)
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }