<template>
  <div
    class="modal-container"
    @click.self="cancel"
    @keypup.escape="cancel"
  >
    <div class="modal-window" @keyup.escape="cancel" @keyup.enter="save">
      <label for="task-name-input">{{ $t("task_name") }}
        <input id="task-name-input" v-model="task.name" />
      </label>
      <label for="task-category-input">{{ $t("task_category") }}
        <tag-selector
          v-model="task.category"
          id="task-category-input"
          :suggest="suggestCategory" />
    </label>
    <label for="task-priority-input">{{ $t("task_priority") }}
      <select v-model="task.priority">
        <option
          v-for="p in priorities"
          :key="`option-priority-${p}`"
          :value="numPriority(p)" >
          {{ $t(`task_priority_${p}`) }}
        </option>
      </select>
    </label>
    <label for="task-tags-input">{{ $t("task_tags") }}
      <tag-selector id="tag-selector-input" v-model="task.tags" />
  </label>
  <button @click="save" class="save">{{ $t("save") }}</button>
</div>
  </div>
</template>

<script>
import Task from '@/core/models/Task';
import TagSelector from '@/components/TagSelector.vue';

import { priority } from '@/core/constants';
import { arrayStartsWith } from '@/core/utils/arrayUtils';

const testCategories = [
  ['projects', 'personal', 'to commercialize'],
  ['projects', 'todox'],
  ['learning', 'japanese'],
  ['leasure', 'anime'],
];

export default {
  components: {
    TagSelector,
  },
  props: {
    task: {
      type: Task,
      required: true,
    },
  },
  data() {
    return {
      priorities: [
        'critical',
        'high',
        'normal',
        'low',
        'idle',
      ],
      categories: testCategories,
    };
  },
  mounted() {
    document.getElementById('task-name-input').focus();
  },
  methods: {
    updateCategory(selected) {
      this.task.category = selected;
    },
    save() {
      this.$emit('save', this.task);
    },
    cancel() {
      this.$emit('cancel');
    },
    suggestCategory(input) {
      return testCategories
        .filter(c => arrayStartsWith(c.map(cpart => cpart.toLowerCase()),
          this.task.category.map(cpart => cpart.toLowerCase()))
            && new RegExp(`^${input}`, 'ui').test(c[this.task.category.length]))
        .map(suggestion => suggestion[this.task.category.length])
        .reduce((acc, v) => acc.add(v), new Set());
    },
    numPriority(p) {
      return priority[p.toUpperCase()];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_colors.scss';
@import '@/scss/_fonts.scss';
@import '@/scss/_modals.scss';

$color-border: scale-color($color-light,  $lightness: -70%, $saturation: -30%);

.modal-container {
  @include modal-container($color-primary);
}

.modal-window {
  @include modal-window($color-light, $color-border);
  padding-bottom: 20px;

  label {
    display: grid;
    grid-template-columns: [labels] 100px [fields] auto;
    align-items: center;
    justify-items: stretch;
  }

  input {
    padding: 7px 5px;
  }

  button {
    @include modal-button(scale-color($color-accent, $lightness: -50%, $saturation: -30%));
  }

  .save {
    background-color: $color-accent;
    padding: 0 5px;
    margin-top: 30px;
  }
}
</style>
