var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "time-entries" }, [
      _vm._v(_vm._s(_vm.$t("task_time_entries_header")))
    ]),
    _vm.timeEntries && _vm.timeEntries.length
      ? _c(
          "ul",
          _vm._l(_vm.timeEntries, function(te) {
            return _c("time-entry-list-item", {
              key: "time-entry-" + te._id,
              attrs: { timeEntry: te },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(slot) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "task-button",
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(slot.timeEntry)
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", { attrs: { icon: "edit" } })
                          ],
                          1
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "task-button",
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(slot.timeEntry)
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "trash-alt" }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          1
        )
      : _vm.timeEntries
      ? _c("p", { staticClass: "empty-placeholder" }, [
          _vm._v(" " + _vm._s(_vm.$t("no_time_entries")) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }