import debug from '@/debug';

const LOCAL_DB = Symbol('local pouch db instance');
const REMOTE_DB = Symbol('remote pouch db instance');
const SYNC = Symbol('sync handler');
const LISTENERS = Symbol('Event listeners');

function isSameDb(a, b) {
  return a.prefix === b.prefix
    && a.name === b.name
    && a.adapter === b.adapter;
}

function emit(event, data) {
  const promises = [];
  this[LISTENERS][event].forEach(l => promises.push(l(data)));
  return Promise.all(promises);
}

class PouchDbAdapter {
  constructor(localDatabase) {
    this[LISTENERS] = {
      reload: new Map(),
      change: new Map(),
      pause: new Map(),
      resume: new Map(),
      error: new Map(),
    };
    this.reset(localDatabase);
  }

  get initialized() {
    return !!this[LOCAL_DB];
  }

  reset(localDatabase) {
    return this.stopSync()
      .catch(err => emit.call(this, 'error', err))
      .then(() => {
        this[LOCAL_DB] = localDatabase;
        this[REMOTE_DB] = null;
        debug('emitting reload from PoucDbAdapter.reset');
        emit.call(this, 'reload');
      });
  }

  /**
   * Gets document range from database.
   * @returns Promise<[doc]>
   */
  getRange(options) {
    return this[LOCAL_DB]
      .allDocs(options)
      .then(response => response.rows);
  }

  /**
   * Gets document from database.
   * Returns a promise resolving to latest winning revision of specified document
   * Returns a promise that resolves to null when document was not found
   */
  get(_id) {
    return this[LOCAL_DB].get(_id)
      .catch((err) => {
        if (err.reason !== 'missing') {
          console.error(err);
          throw err;
        }
        return null;
      });
  }

  /**
   * Inserts new document to the database.
   * Throws error if document with given _id already exists.
   * Returns a promise.
   */
  insert(doc) {
    return this[LOCAL_DB]
      .get(doc._id)
      .then((oldDoc) => {
        const err = new Error(`Document ${doc._id} already exists`);
        err.code = 'conflict';
        err.detail = 'already_exists';
        err.value = oldDoc;
        throw err;
      })
      .catch((err) => {
        if (err.reason !== 'missing') {
          console.error(err);
          throw err;
        }
        return this[LOCAL_DB].put(doc);
      });
  }

  /**
   * Updates document in the database.
   * Throws error if the document does not exist.
   * Returns a promise.
   */
  update(doc) {
    return this[LOCAL_DB]
      .get(doc._id)
      .then(({ _rev }) => this[LOCAL_DB].put(Object.assign(doc, { _rev })))
      .catch((error) => {
        if (error.reason === 'missing') {
          const err = new Error(`Failed to update doc: ${error.message}`);
          err.code = 'conflict';
          err.details = 'not_exist';
          err.value = error;
          throw err;
        } else {
          throw error;
        }
      });
  }

  /**
   * Inserts document if it does not exist, updates otherwise.
   * This is pretty much like update, but instead of throwing inserts
   * as new.
   * Returns a promise.
   */
  upsert(doc) {
    return this[LOCAL_DB]
      .get(doc._id)
      .then(({ _rev }) => this[LOCAL_DB].put(Object.assign(doc, { _rev })))
      .catch((err) => {
        if (err.reason !== 'missing') {
          console.error(err);
          throw err;
        }
        return this[LOCAL_DB].put(doc);
      });
  }

  /**
   * Deletes document.
   * If the document does not exists does nothing.
   * Returns a promise.
   */
  delete(doc) {
    return this[LOCAL_DB]
      .get(doc._id)
      .then(({ _rev }) => this[LOCAL_DB].put(Object.assign(doc, { _rev, _deleted: true })))
      .catch((err) => {
        if (err.reason !== 'missing') throw err;
      });
  }

  async query(...args) {
    const results = await this[LOCAL_DB].query(...args);
    return results.rows;
  }

  startSync(remoteDb) {
    return new Promise((resolve, reject) => {
      if (this[REMOTE_DB]) {
        if (!isSameDb(this[REMOTE_DB], remoteDb)) {
          reject(new Error('Already syncing with another database'));
        } else {
          resolve();
        }
        return;
      }
      this[REMOTE_DB] = remoteDb;
      debug('Starting sync...');
      this[LOCAL_DB].replicate.to(remoteDb).on('complete', () => {
        debug('Completed sync to remote.');
        this[LOCAL_DB].replicate.from(remoteDb).on('complete', () => {
          debug('completed sync from remote.');
          this[SYNC] = this[LOCAL_DB].sync(this[REMOTE_DB], { live: true, retry: true });
          this[SYNC].on('change', (data) => {
            debug('sync change', data);
            emit.call(this, 'change', data);
          });
          this[SYNC].on('paused', (data) => {
            debug('sync paused', data);
            emit.call(this, 'pause', data);
          });
          this[SYNC].on('active', (data) => {
            debug('sync active', data);
            emit.call(this, 'resume', data);
          });
          this[SYNC].on('error', (data) => {
            console.error('sync error', data);
            emit.call(this, 'error', data);
          });
          debug('emitting reload from PoucDbAdapter.startSync');
          emit.call(this, 'reload').then(resolve).catch(reject);
          resolve();
        }).on('error', reject);
      }).on('error', reject);
    });
  }

  /**
   * Logs the user out if logged in.
   * Does nothing if not.
   */
  async stopSync() {
    if (!this[SYNC]) return;
    this[SYNC].cancel();
    this[SYNC] = null;
    this[REMOTE_DB] = null;
  }

  on(event, key, callback) {
    this[LISTENERS][event].set(key, callback);
  }

  clearEvents(key) {
    Object.keys(this[LISTENERS]).forEach(ev => this[LISTENERS][ev].delete(key));
  }
}

export default PouchDbAdapter;
