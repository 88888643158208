export class AssertionError extends Error {
  constructor(expected, actual, message) {
    super(message);
    this.code = 'assertion_error';
    this.expected = expected;
    this.actual = actual;
  }
}

export class ValidationError extends Error {
  constructor(rule, value) {
    super(rule);
    this.code = 'validation_error';
    this.rule = rule;
    this.value = value;
  }
}

export function assertGTE(a, b, message) {
  if (a < b) {
    throw new AssertionError(b, a, message || 'Assertion error');
  }
}

export function assertEquals(expected, actual, message) {
  if (expected !== actual) {
    throw new AssertionError(expected, actual, message || 'Assertion error');
  }
}

export function assertInstance(value, _class) {
  if (!(value instanceof _class)) {
    throw new AssertionError(value, _class, `Expected instance of ${_class}`);
  }
}
