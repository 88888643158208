var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { attrs: { id: "notifs" } },
      [
        _c("toast-notification", { attrs: { toasts: _vm.toasts } }),
        _c("sync-indicator")
      ],
      1
    ),
    _c(
      "nav",
      [
        _c("time-period-selector", {
          model: {
            value: _vm.dates,
            callback: function($$v) {
              _vm.dates = $$v
            },
            expression: "dates"
          }
        })
      ],
      1
    ),
    _c("main", [
      _c("section", { ref: "charts", staticClass: "charts" }, [
        this.tasks
          ? _c(
              "svg",
              { attrs: { width: _vm.chartWidth, height: _vm.chartHeight } },
              [
                _c("stat-chart-sunwheel", {
                  attrs: {
                    radius: _vm.chartWidth / 4,
                    tasks: _vm.taskStats,
                    selected: _vm.selected,
                    sticky: _vm.sticky
                  },
                  on: { select: _vm.handleSelect, deselect: _vm.handleDeselect }
                }),
                _c("g", {
                  attrs: { transform: "`translate(${chartWidth/2}, 0)`" }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      this.tasks
        ? _c(
            "section",
            { staticClass: "summary" },
            [
              _c("stat-summary", {
                attrs: {
                  tasks: _vm.taskStats,
                  selected: _vm.selected,
                  sticky: _vm.sticky
                },
                on: { select: _vm.handleSelect, deselect: _vm.handleDeselect }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }