import debug from '@/debug';

const DB = Symbol('DB');
const FACTORY = Symbol('FACTORY');
const CACHE = Symbol('Cache');
const TASKS_LOADED = Symbol('TASKS LOADED');
const SYNC_ACTIVITY_LISTENERS = Symbol('Sync activity liteners');

const unknownTaskDAO = _id => ({
  _id,
  is: 'task',
  name: '?',
  category: [],
  tags: [],
});

function updateCache(newValues) {
  debug('Updating cache...');
  const keys = Object.keys(newValues);
  for (let i=0; i<keys.length; i++) {
    const key = keys[i];
    for (let j=0; j<newValues[key].length; j++) {
      const val = newValues[key][j];
      const idx = this[CACHE][key].findIndex(o => o._id === val._id);
      if (idx === -1) {
        if (!val._deleted) {
          this[CACHE][key].push(val);
        }
      } else if (val._deleted) {
        this[CACHE][key].splice(idx, 1);
      } else {
        this[CACHE][key].splice(idx, 1, val);
      }
    }
  }
  debug('Cache updated.');
}

function taskNameIndex(doc, emit) {
  if (doc.name && doc.is && doc.is === 'task') {
    emit(String(doc.name).toLowerCase(), 1);
  }
}

function mapToDao(dbObjects) {
  return dbObjects.reduce((acc, v) => {
    if (!acc[v.is]) acc[v.is] = [];
    acc[v.is].push(this[FACTORY].makeDAO(v));
    return acc;
  }, {});
}

function makeModels(dbObjects) {
  debug('Creating task models...');
  const dao = mapToDao.call(this, dbObjects);
  const models = {};
  const objTypes = Object.keys(dao);
  if (dao.task) {
    models.task = dao.task.map(this[FACTORY].makeSimpleModel);
    updateCache.call(this, models);
  }
  debug('task models created, creating child models');
  for (let i=0; i<objTypes.length; i++) {
    if (objTypes[i] === 'task') continue;
    models[objTypes[i]] = dao[objTypes[i]].map((_dao) => {
      let task = this[CACHE].task.find(t => t._id === _dao.taskId);
      if (!task) {
        // try to create dummy task to prevent errors in the app
        task = this[FACTORY].makeSimpleModel(unknownTaskDAO(_dao.taskId));
        if (task) {
          if (!models.task) models.task = [];
          models.task.push(task);
        } else if (process.env.NODE_ENV === 'test') {
          console.warn('Cannot create dummy task, but NODE_ENV is test, skipping');
        } else {
          throw new Error('Cannot create dummy task!');
        }
      }
      return this[FACTORY].makeChildModel(_dao, task);
    });
  }
  updateCache.call(this, models);
  debug('all models created');
  return models;
}

async function updateRelated(related) {
  const sepIdx = related._id.indexOf('*');
  const taskId = sepIdx === -1 ? related._id.slice(0, sepIdx) : related._id;
  Object.values(this[CACHE])
    .flat(1)
  // .reduce((acc, v) => { acc.push(...v); return acc; }, []) <-- pre-node12, instead of flat(1)
    .filter(o => o._id.startsWith(taskId) && typeof o.update === 'function')
    .forEach(model => model.update(related));
}

class TaskRepository {
  constructor(dbAdapter, factory) {
    dbAdapter.on('reload', this, this.reloadHandler.bind(this));
    dbAdapter.on('change', this, this.changeHandler.bind(this));
    dbAdapter.on('pause', this, this.pauseHandler.bind(this));
    dbAdapter.on('resume', this, this.resumeHandler.bind(this));
    this[DB] = dbAdapter;
    this[FACTORY] = factory;
    this[TASKS_LOADED] = false;
    this[SYNC_ACTIVITY_LISTENERS] = new Map();
    this[CACHE] = {
      task: [],
      planned_task: [],
      time_entry: [],
    };
  }

  get cache() {
    return this[CACHE];
  }

  onSyncStateChange(thisArg, method) {
    this[SYNC_ACTIVITY_LISTENERS].set(thisArg, method.bind(thisArg));
  }

  offSyncStateChange(thisArg) {
    this[SYNC_ACTIVITY_LISTENERS].delete(thisArg);
  }

  async save(savable) {
    const dao = savable.toDAO();
    const retval = await this[DB].upsert(dao);
    updateCache.call(this, { [dao.is]: [savable] });
    await updateRelated.call(this, savable);
    if (savable.timeEntries && savable.timeEntries.length) {
      await Promise.all(savable.timeEntries.map(this.save.bind(this)));
    }
    if (savable.plannedTasks && savable.plannedTasks.length) {
      await Promise.all(savable.plannedTasks.map(this.save.bind(this)));
    }
    return retval;
  }

  async getAll() {
    debug('getAll()');
    const rows = await this[DB].getRange({ include_docs: true });
    debug(`got ${rows.length} rows, creating models...`);
    makeModels.call(this, rows.map(r => r.doc));
    debug('models created.');
    this[TASKS_LOADED] = true;
  }

  async load() {
    if (!this[TASKS_LOADED]) {
      await this.getAll();
    }
    return this[CACHE];
  }

  async unload() {
    const keys = Object.keys(this[CACHE]);
    for (let i=0; i<keys.length; i++) {
      const key = keys[i];
      if (key === 'task') continue;
      this[CACHE][key].splice(0, this[CACHE][key].length);
    }
  }

  async delete(savable) {
    const dao = savable.toDAO();
    const key = dao.is;
    // eslint-disable-next-line no-param-reassign
    savable._deleted = true;
    updateRelated.call(this, savable);
    updateCache.call(this, { [key]: [savable] });
    if (savable.updateParent) savable.updateParent(true);
    return this[DB].delete(dao);
  }

  async get(_id) {
    const row = await this[DB].get(_id);
    if (!row) return null;
    const dao = this[FACTORY].makeDAO(row);
    if (dao.taskId) {
      const task = await this.get(dao.taskId);
      return this[FACTORY].makeChildModel(dao, task);
    }
    return this[FACTORY].makeSimpleModel(dao);
  }

  async findTaskByName(name) {
    const n = String(name).toLowerCase();
    const rows = await this[DB].query(taskNameIndex, {
      startkey: n,
      endkey: `${n}\uffff`,
      include_docs: true,
    });
    return rows.map(r => this[FACTORY].makeSimpleModel(this[FACTORY].makeDAO(r.doc)));
  }

  getParentTask(model) {
    if (model.taskId) {
      return this.get(model.taskId);
    }
    return Promise.resolve(null);
  }

  async getChildren(model, type) {
    const rows = await this[DB].getRange({
      include_docs: true,
      startkey: `${model._id}*`,
      endkey: `${model._id}\xff`,
    });
    let docs = rows.map(d => d.doc);
    if (type) {
      docs = docs.filter(d => d.is === type);
    }
    const dao = docs.map(this[FACTORY].makeDAO);
    return dao.map(d => this[FACTORY].makeChildModel(d, model));
  }

  // database event handlers

  async changeHandler(data) {
    if (data.direction === 'push') {
      if (!data.change.ok) {
        console.warn('update failed', data);
      }
    } else {
      const models = makeModels.call(this, data.change.docs);
      Object.keys(models).forEach((k) => {
        models[k].forEach((m) => {
          updateRelated.call(this, m);
        });
      });
    }
  }

  pauseHandler() {
    this[SYNC_ACTIVITY_LISTENERS].forEach(l => l('pause'));
  }

  resumeHandler(info) {
    this[SYNC_ACTIVITY_LISTENERS].forEach(l => l('resume', info.direction));
  }

  reloadHandler(force = false) {
    if ((this[TASKS_LOADED] && this[DB].initialized) || force) {
      this[CACHE].task.splice(0, this[CACHE].task.length);
      this[CACHE].planned_task.splice(0, this[CACHE].planned_task.length);
      this[CACHE].time_entry.splice(0, this[CACHE].time_entry.length);
      this[TASKS_LOADED] = false;
      this.load();
    }
  }
}

export default TaskRepository;
