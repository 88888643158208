<template>
  <li
    class="suggestion-box-task"
    @mousedown.prevent="/* prevent blur */"
    @click="$emit('click', idx)"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div :class="rootClasses">
      <div class="category-bar">
        <div
          class="category-name"
          v-for="(c, idx) in category"
          :key="categoryPartKey(category, idx)">
          {{ c }}
        </div>
      </div>
    <div class="task-main">
      <div class="task-name">{{ name }}</div>
      <div class="task-priority">{{ $t(`task_priority_${priority}`) }}</div>
    </div>
    <div class="tags">
      <div
        class="tag"
        v-for="(t, idx) in tags"
        :key="tagKey(tags, idx)">
        {{ t }}
      </div>
    </div>
  </div>
  </li>
</template>

<script>
import { hue } from '@/core/utils/taskUtils';

export default {
  name: 'suggestion-box-item',
  props: {
    _id: String,
    category: Array,
    name: String,
    tags: Array,
    priority: Number,
    selected: Boolean,
    idx: Number,
  },
  mounted() {
    const mainCategory = this.category.length ? this.category[0] : '';
    this.$el.style['border-color'] = `hsl(${hue(mainCategory)}, 70%, 60%)`;
  },
  computed: {
    rootClasses() {
      const classes = ['suggestion-box-task-wrapper'];
      if (this.selected) {
        classes.push('selected');
      }
      return classes;
    },
  },
  methods: {
    categoryPartKey(category, idx) {
      return `${category[idx]}-${idx}`;
    },
    tagKey(tags, idx) {
      return tags[idx];
    },
    handleMouseEnter(ev) {
      if (ev.target === this.$el) {
        this.$emit('mouseSelect', this.idx);
      }
    },
    handleMouseLeave(ev) {
      if (ev.target === this.$el) {
        this.$emit('mouseDeselect', this.idx);
      }
    },
  },
};
</script>

<style>
.suggestion-box-task {
  width: 100%;
  margin: 0;
  padding: 0;
  border-left: 5px solid silver;
}
.suggestion-box-task-wrapper {
  margin: 0 0 0.2em 0;
  padding: 0.2em;
}
.suggestion-box-task-wrapper:nth-child(2n) {
  background: #eeeeee;
}
.suggestion-box-task-wrapper:last-child {
  margin-bottom: 0;
}
.suggestion-box-task-wrapper.selected, .suggestion-box-task-wrapper.selected:nth-child(2n) {
  background-color: #6080ff;
}
.category-name {
  margin: 2px;
  font-size: 8pt;
  border-radius: 5px;
  padding: 0 0.2em;
  display: inline-block;
}
.category-name::after {
  content: '\00bb';
}
.category-name:last-child::after {
  content: '';
}
.tags {
  display: flex;
  font-size: 8pt;
  height: 18px;
}
.tags>* {
  background: #ccc;
  margin: 2px;
  padding: 2px;
}
.task-main {
  display: grid;
  grid-template-columns: auto 7em;
}
</style>
