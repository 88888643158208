var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "time-entry" }, [
    _vm.timeEntry
      ? _c("div", { staticClass: "date" }, [
          _vm._v(_vm._s(_vm.timeEntry.start))
        ])
      : _vm._e(),
    _vm.timeEntry
      ? _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))])
      : _vm._e(),
    _vm.timeEntry
      ? _c(
          "div",
          { staticClass: "running" },
          [
            !_vm.timeEntry.end
              ? _c("font-awesome-icon", {
                  staticClass: "blinking",
                  attrs: { icon: ["far", "clock"] }
                })
              : _vm._e(),
            _vm._t("default", null, { timeEntry: _vm.timeEntry })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }