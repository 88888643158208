<template>
  <ul v-if="tasks">
    <task-timer-list-item
      v-for="task in tasks"
      :timeEntry="task"
      :key="`timer-${task._id}`"
      @stop="handleStop"
     />
  </ul>
  <img v-else :src="loader" alt="$t('loading')" />
</template>

<script>
import TaskTimerListItem from '@/components/TaskTimerListItem.vue';
import TimeEntry from '@/core/models/TimeEntry';

export default {
  components: {
    TaskTimerListItem,
  },
  props: {
    tasks: {
      required: true,
      validator(value) {
        if (value === null || value === undefined) return true;
        return Array.isArray(value) && !value.find(t => !(t instanceof TimeEntry));
      },
    },
    loader: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleStop(timeEntry) {
      this.$manager.stop(timeEntry);
      this.$manager.save(timeEntry.task);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
