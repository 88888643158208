class PlannedTaskDAO {
  constructor(data) {
    Object.defineProperty(this, 'is', {
      enumerable: true,
      writable: false,
      configurable: false,
      value: 'planned_task',
    });
    this.date = data.date;
    this.duration = data.duration;
    this.tags = Object.assign([], data.tags);
    this._id = data._id;
    this.done = data.done || false;
    if (data.debug) {
      this.debug = data.debug;
    }
  }

  get taskId() {
    return this._id.split('*')[0];
  }
}

export default PlannedTaskDAO;
