<template>
  <div>
    <h4 class="time-entries">{{ $t('task_time_entries_header') }}</h4>
    <ul v-if="timeEntries && timeEntries.length">
      <time-entry-list-item
        v-for="te in timeEntries"
        :timeEntry="te"
        :key="`time-entry-${te._id}`">
        <template v-slot:default="slot">
          <button class="task-button" @click="handleEdit(slot.timeEntry)">
            <font-awesome-icon icon="edit" />
          </button>
          <button class="task-button" @click="handleDelete(slot.timeEntry)">
            <font-awesome-icon icon="trash-alt" />
          </button>
        </template>
      </time-entry-list-item>
    </ul>
    <p v-else-if="timeEntries" class="empty-placeholder">
      {{ $t('no_time_entries') }}
    </p>
  </div>
</template>

<script>
import TimeEntryListItem from '@/components/TimeEntryListItem.vue';
import TimeEntry from '@/core/models/TimeEntry';

export default {
  components: {
    TimeEntryListItem,
  },
  props: {
    timeEntries: {
      required: true,
      validator(value) {
        if (value === null || value === undefined) return true;
        return Array.isArray(value) && !value.find(t => !(t instanceof TimeEntry));
      },
    },
  },
  methods: {
    handleEdit(timeEntry) {
      this.$emit('edit', timeEntry);
    },
    handleDelete(timeEntry) {
      this.$emit('delete', timeEntry);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
h4 {
  font-size: 12pt;
  font-weight: bold;
  text-decoration: none;
  display: block;
  border-bottom: 3px double #333;
  margin: 0 0 0.5em 0;
  text-align: left;
}
.empty-placeholder {
  color: #333;
  font-size: 10pt;
  font-style: italic;
  text-align: center;
}
</style>
