<template>
  <div>
    <section id="notifs">
      <toast-notification :toasts="toasts" />
      <sync-indicator />
    </section>
    <nav>
      <time-period-selector v-model="dates" />
    </nav>
    <main>
      <section class="charts" ref="charts">
        <svg v-if="this.tasks" :width="chartWidth" :height="chartHeight">
          <stat-chart-sunwheel
            :radius="chartWidth/4"
            :tasks="taskStats"
            :selected="selected"
            :sticky="sticky"
            @select="handleSelect"
            @deselect="handleDeselect" />
          <g transform="`translate(${chartWidth/2}, 0)`">
          </g>
        </svg>
      </section>
      <section v-if="this.tasks" class="summary">
        <stat-summary
          :tasks="taskStats"
          :selected="selected"
          :sticky="sticky"
          @select="handleSelect"
          @deselect="handleDeselect" />
      </section>
    </main>
  </div>
</template>

<script>
import StatSummary from '@/components/StatSummary.vue';
import StatChartSunwheel from '@/components/StatChartSunwheel.vue';
import ToastNotification from '@/components/ToastNotification.vue';
import SyncIndicator from '@/components/SyncIndicator.vue';
import TimePeriodSelector from '@/components/TimePeriodSelector.vue';
import {
  WEEK,
  tomorrow,
} from '@/core/utils/dateUtils';
import { compareByArray, arrayEquals } from '@/core/utils/arrayUtils';
import taskStatCalculator from '@/core/utils/taskStatCalculator';

export default {
  components: {
    StatSummary,
    StatChartSunwheel,
    ToastNotification,
    SyncIndicator,
    TimePeriodSelector,
  },
  data() {
    return {
      chartWidth: 0,
      chartHeight: 0,
      dates: [new Date(tomorrow().getTime() - WEEK), tomorrow()],
      tasks: null,
      plannedTasks: null,
      timeEntries: null,
      selected: null,
      sticky: null,
      toasts: this.$getToasts(),
    };
  },
  created() {
    this.$auth.refresh()
      .then(() => this.$manager.init())
      .then((models) => {
        this.tasks = models.task;
        this.plannedTasks = models.planned_task;
        this.timeEntries = models.time_entry;
      })
      .catch(err => this.$toast('error', `Error: ${err}`));
  },
  mounted() {
    this.chartWidth = Math.max(500, this.$refs.charts.clientWidth);
    this.chartHeight = Math.max(500, this.$refs.charts.clientHeight);
  },
  methods: {
    handleSelect(key, sticky) {
      if (this.sticky && !sticky) return;
      this.selected = key;
      this.sticky = sticky;
    },
    handleDeselect(key, sticky) {
      if (this.sticky && !sticky) return;
      if (!this.selected) return;
      if (sticky || arrayEquals(this.selected, key)) {
        this.sticky = false;
        this.selected = null;
      }
    },
  },
  computed: {
    filteredTasks() {
      return this.tasks;
    },
    sortedTasks() {
      return this.filteredTasks.slice().sort((a, b) => compareByArray(a, b, obj => obj.category));
    },
    taskStats() {
      return taskStatCalculator(this.sortedTasks, this.dates);
    },
  },
};
</script>

<style scoped>
main {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 90vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.summary {
  margin: 0 1em;
}
</style>
