<template>
  <div>
    <section id="notifs">
      <toast-notification :toasts="toasts" />
      <sync-indicator />
    </section>
    <nav id="debug-panel">
      <time-period-selector :value="dates" @input="changeDates" />
      <label for="debug-switch-include-completed">
        <input type="checkbox" id="debug-switch-include-completed" v-model="includeCompleted" />
        Include completed
      </label>
    </nav>
    <main>
      <section id="task-column">
        <quick-add-task
          :categories="allCategories"
          @start-new="handleQuickStart"
          @start-task="handleStart"
          @add-task="handleAddTask"
        />
        <div id="all-tasks">
          <h3>Other</h3>
          <task-list
            :tasks="filteredTasks"
            :includeCompleted="includeCompleted"
            loader="/img/loader.gif"
            @edit="handleEdit"
            @delete="handleDelete"
            @update="handleSave"
            @done="handleMarkDone"
            @start="handleStart"
            @stop="handleStop"
            />
        </div>
        <div class="actions">
          <button class="add-task" @click="handleAddTask">
            <font-awesome-icon icon="plus-square" />
            {{ $t('new_task') }}
          </button>
        </div>
      </section>

      <section id="work-column">
        <div id="running">
          <task-timer-list
            :tasks="runningTasks"
            @update="handleSave"
            loader="/img/loader.gif"
            />
        </div>
        <div id="planned-today">
          <h3>Today</h3>
          <task-list-planned
            :hasStartButton="true"
            :tasks="plannedToday"
            :includeCompleted="includeCompleted"
            loader="/img/loader.gif"
            @edit="handleEdit"
            @delete="handleDelete"
            @update="handleSave"
            @done="handleMarkDone"
            @start="handleStart"
            @stop="handleStop"
            />
        </div>
        <div id="planned-tomorrow">
          <h3>Tomorrow</h3>
          <task-list-planned
            :tasks="plannedTomorrow"
            :includeCompleted="includeCompleted"
            loader="/img/loader.gif"
            @edit="handleEdit"
            @delete="handleDelete"
            @update="handleSave"
            @done="handleMarkDone"
            />
        </div>
      </section>

      <!-- modals -->
      <task-editor
        v-if="editingTask"
        :task="editedItem"
        @cancel="handleCancelSave"
        @save="handleSave" />
      <time-entry-editor
        v-if="editingTimeEntry"
        :timeEntry="editedItem"
        @cancel="handleCancelSave"
        @save="handleSave" />
      <confirmation-popup
        v-if="question"
        :context="confirmationContext"
        :message="question"
        @no="handleNo"
        @yes="handleYes" />

    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import PlannedTask from '@/core/models/PlannedTask';
import TimeEntry from '@/core/models/TimeEntry';
import TaskList from '@/components/TaskList.vue';
import TaskListPlanned from '@/components/TaskListPlanned.vue';
import TaskEditor from '@/components/TaskEditor.vue';
import TimeEntryEditor from '@/components/TimeEntryEditor.vue';
import TaskTimerList from '@/components/TaskTimerList.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
import QuickAddTask from '@/components/QuickAddTask.vue';
import ToastNotification from '@/components/ToastNotification.vue';
import SyncIndicator from '@/components/SyncIndicator.vue';
import TimePeriodSelector from '@/components/TimePeriodSelector.vue';

import debug from '@/debug';

import {
  WEEK,
  today,
  tomorrow,
  asatte,
  startOfMonth,
} from '@/core/utils/dateUtils';

function isToday(date) {
  return (date >= today()) && (date < tomorrow());
}
function isTomorrow(date) {
  return (date >= tomorrow()) && (date < asatte());
}

export default {
  name: 'home',
  components: {
    TaskList,
    TaskListPlanned,
    TaskEditor,
    TimeEntryEditor,
    TaskTimerList,
    ConfirmationPopup,
    QuickAddTask,
    ToastNotification,
    SyncIndicator,
    TimePeriodSelector,
  },
  data() {
    return {
      tasks: null,
      plannedTasks: null,
      timeEntries: null,
      editingTask: false,
      editingTimeEntry: false,
      editedItem: null,
      modalCallback: null,
      orig: null,
      question: null,
      confirmationContext: null,
      includeCompleted: false,
      toasts: this.$getToasts(),
      dates: [startOfMonth(new Date(tomorrow().getTime() - WEEK*6)), tomorrow()],
    };
  },
  mounted() {
    this.$auth.refresh()
      .then(() => this.refreshData())
      .catch(err => console.error(err));
  },
  computed: {
    plannedToday() {
      if (!this.plannedTasks) return null;
      return this.plannedTasks.filter(t => isToday(t.date) && !t.task.done);
    },
    plannedTomorrow() {
      if (!this.plannedTasks) return null;
      return this.plannedTasks.filter(t => isTomorrow(t.date) && !t.task.done);
    },
    runningTasks() {
      return this.timeEntries ? this.timeEntries.filter(t => !t.end) : null;
    },
    allCategories() {
      if (!this.tasks) return [];
      return this.tasks.map(t => t.category);
    },
    filteredTasks() {
      return this.tasks && this.tasks.filter(t => t.createdBetween(this.dates[0], this.dates[1])
        || t.timeEntries.some(te => te.start >= this.dates[0] && te.end < this.dates[1]
          || !te.end
          || te.end >= this.dates[0] && te.end < this.dates[1])
        || t.plannedTasks.some(pt => isToday(pt.start) || isTomorrow(pt.start)));
    },
  },
  methods: {
    refreshData() {
      debug('data refresh started');
      return this.$manager.init({ since: this.dates[0], until: this.dates[1] })
        .then((models) => {
          debug('data refreshed');
          this.tasks = models.task;
          this.plannedTasks = models.planned_task;
          this.timeEntries = models.time_entry;
        });
    },
    handleEdit(model) {
      if (model instanceof TimeEntry) {
        this.handleEditTimeEntry(model);
      } else if (model.task) {
        this.handleEditTask(model.task);
      } else {
        this.handleEditTask(model);
      }
    },
    handleEditTimeEntry(timeEntry) {
      this.orig = Object.assign({}, timeEntry);
      this.editedItem = timeEntry;
      this.editingTask = false;
      this.editingTimeEntry = true;
    },
    handleEditTask(task) {
      this.orig = Object.assign({}, task);
      this.editedItem = task;
      this.editingTimeEntry = false;
      this.editingTask = true;
    },
    handleAddTask(callback) {
      this.orig = null;
      this.editedItem = this.$manager.newTask();
      this.editingTimeEntry = false;
      this.editingTask = true;
      this.modalCallback = callback;
    },
    handleQuickStart(data) {
      const task = this.$manager.newTask(data);
      this.handleStart(task);
    },
    handleMarkDone(task) {
      let method = 'mark';
      if (task.done) {
        method = `un${method}`;
      }
      if (task instanceof PlannedTask) {
        method += 'Planned';
      }
      method += 'TaskCompleted';
      this.$manager[method](task);
    },
    handleDelete(item) {
      this.confirmationContext = {
        fn: this.performDelete,
        _this: this,
        args: [item],
      };
      // FIXME: provide type-aware messages (task, plan, time entry)
      this.question = this.$t('confirm_delete_item', { item });
    },
    handleStart(task) {
      this.timeEntries
        .filter(te => !te.end)
        .forEach((te) => {
          this.$manager.stop(te);
          this.$manager.save(te);
        });
      this.$manager.startTask(task);
      this.$manager.save(task);
    },
    handleStop(task) {
      task.timeEntries
        .filter(te => te.start && !te.end)
        .forEach(te => this.$manager.stop(te));
      this.$manager.save(task);
    },
    performDelete(item) {
      return this.$manager.delete(item);
    },
    handleSave(item) {
      this.$manager.save(item);
      this.editedItem = null;
      this.orig = null;
      this.editingTimeEntry = false;
      this.editingTask = false;
      if (this.modalCallback) {
        this.modalCallback(item);
      }
      this.modalCallback = null;
    },
    handleCancelSave() {
      Object.assign(this.editedItem, this.orig);
      this.editedItem = null;
      this.orig = null;
      this.editingTimeEntry = false;
      this.editingTask = false;
      this.modelCallback = null;
    },
    handleNo() {
      this.confirmationContext = null;
      this.question = null;
    },
    handleYes(context) {
      context.fn.apply(context._this, context.args)
        .then(() => {
          this.confirmationContext = null;
          this.question = null;
        });
    },
    changeDates(v) {
      this.dates = v;
      this.refreshData().catch(err => console.error(err));
    },
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 799px) {
  #running {
    border: none;
    border-top: 1px solid black;
    background-color: inherit;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    & ul {
      max-height: 120px;
      overflow-y: scroll;
    }

    & .time-entry {
      border: none;
    }
  }

  main {
    margin-bottom: 130px;
  }
}

@media only screen and (min-width: 800px) {
  main {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  #task-column {
    grid-column: 1;
  }

  #work-column {
    grid-column: 2;
  }
}
</style>
