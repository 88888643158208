<template>
  <div>
    <section id="notifs">
      <toast-notification :toasts="toasts" />
      <sync-indicator />
    </section>
    <main>
      <div>
        <nav id="planning-day">
          <button class="datepicker-button prev" @click="prevDay" :disabled="!prevDayEnabled">
            <font-awesome-icon icon="caret-left" />
          </button>
          <vue-datepicker
            type="date"
            :clearable="false"
            :disabled-date="isDateDisabled"
            :value="date"
            @change="changeDate"
            />
          <button class="datepicker-button next" @click="nextDay">
            <font-awesome-icon icon="caret-right" />
          </button>
        </nav>
        <div class="search">
          <input
            placeholder="Search or create task..."
            ref="searchInput"
            id="search-box"
            @keydown.down.prevent="onDown"
            @keydown.up.prevent="onUp"
            @keydown.enter.prevent="onEnter"
            @keydown.tab.prevent="onSelect"
            @focus="showSuggestions"
            @blur="hideSuggestions"
            v-model="term" />
          <suggestion-box
            ref="suggestionBox"
            @select="handleSelected"
            @addTask="handleAddTask"
            :search="term"
            :show="suggestions" />
        </div>
        <div class="planned-tasks">
          <task-list-planned
            :tasks=plannedTasks
            loader="/img/loader.gif"
            @edit="handleEdit"
            @delete="handleDelete"
            @update="handleSave"
            @done="handleMarkDone" />
        </div>
      </div>

      <!-- modals -->
      <confirmation-popup
        v-if="question"
        :context="confirmationContext"
        :message="question"
        @no="handleNo"
        @yes="handleYes" />
      <task-editor
        v-if="editedItem"
        :task="editedItem"
        @cancel="handleCancelSave"
        @save="handleSave" />

    </main>
  </div>
</template>

<script>
import SuggestionBox from '@/components/SuggestionBox.vue';
import TaskListPlanned from '@/components/TaskListPlanned.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
import TaskEditor from '@/components/TaskEditor.vue';
import ToastNotification from '@/components/ToastNotification.vue';
import SyncIndicator from '@/components/SyncIndicator.vue';
import {
  DAY,
  today,
  tomorrow,
} from '@/core/utils/dateUtils';

export default {
  name: 'planning',
  components: {
    SuggestionBox,
    TaskListPlanned,
    ConfirmationPopup,
    TaskEditor,
    ToastNotification,
    SyncIndicator,
  },
  data() {
    return {
      allTasks: null,
      allPlannedTasks: null,
      term: null,
      suggestions: false,
      date: today(),
      editedItem: null,
      modalCallback: null,
      orig: null,
      question: null,
      confirmationContext: null,
      toasts: this.$getToasts(),
    };
  },
  created() {
    this.$auth.refresh()
      .then(() => this.$manager.init())
      .then((models) => {
        this.allTasks = models.task;
        this.allPlannedTasks = models.planned_task;
      })
      .catch(err => console.error(err));
  },
  methods: {
    showSuggestions() {
      this.suggestions = true;
    },
    hideSuggestions() {
      this.suggestions = false;
    },
    changeDate(newDate) {
      if (!this.isDateDisabled(newDate)) {
        this.date = newDate;
      }
    },
    nextDay() {
      this.changeDate(new Date(this.date.getTime()+DAY));
    },
    prevDay() {
      this.changeDate(new Date(this.date.getTime()-DAY));
    },
    isDateDisabled(date) {
      return date < today();
    },
    onDown() {
      this.$refs.suggestionBox.next();
    },
    onUp() {
      this.$refs.suggestionBox.prev();
    },
    onEnter() {
      if (this.$refs.suggestionBox.selectedTaskIdx !== null
        && this.$refs.suggestionBox.selectedTaskIdx !== undefined
      ) {
        this.onSelect();
      }
    },
    onSelect() {
      this.$refs.suggestionBox.select();
    },
    handleAddTask(callback) {
      this.orig = null;
      this.editedItem = this.$manager.newTask();
      this.modalCallback = callback;
    },
    handleSelected(task) {
      const plannedTask = this.$manager.plan(task, this.date);
      this.$manager.save(plannedTask).catch(err => console.error(err));
      this.$refs.searchInput.blur();
      this.term = '';
    },
    handleEdit(timeEntry) {
      this.orig = Object.assign({}, timeEntry.task);
      this.editedItem = timeEntry.task;
    },
    handleMarkDone(task) {
      if (task.done) {
        this.$manager.unmarkTaskCompleted(task);
      } else {
        this.$manager.markTaskCompleted(task);
      }
    },
    handleDelete(item) {
      this.confirmationContext = {
        fn: this.performDelete,
        _this: this,
        args: [item],
      };
      // FIXME: provide type-aware messages (task, plan, time entry)
      this.question = this.$t('confirm_delete_item', { item });
    },
    performDelete(item) {
      return this.$manager.delete(item);
    },
    handleSave(item) {
      this.$manager.save(item);
      this.editedItem = null;
      this.orig = null;
      if (this.modalCallback) {
        this.modalCallback(item);
      }
      this.modelCallback = null;
    },
    handleCancelSave() {
      Object.assign(this.editedItem, this.orig);
      this.editedItem = null;
      this.orig = null;
      this.modelCallback = null;
    },
    handleNo() {
      this.confirmationContext = null;
      this.question = null;
    },
    handleYes(context) {
      context.fn.apply(context._this, context.args)
        .then(() => {
          this.confirmationContext = null;
          this.question = null;
        });
    },
  },
  computed: {
    plannedTasks() {
      return this.allPlannedTasks
        ? this.allPlannedTasks.filter(t => t.date >= this.date && t.date < this.date.getTime()+DAY)
        : null;
    },
    prevDayEnabled() {
      return this.date >= tomorrow();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_colors.scss';

#planning-day {
  display: grid;
  grid-template-columns: 2em 1fr 2em;
}
input {
  width: 100%;
  margin: 0;
}
.search {
  width: 100%;
  position: relative;
  margin: 0.5em 0;
}
.datepicker-button {
  margin-top: 0;
  margin-bottom: 0;
}
.prev {
  margin-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
  border-color: $color-input-border;
}
.next {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  border-color: $color-input-border;
}
</style>
