var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tasks
    ? _c("task-list-base", {
        attrs: { tasks: _vm.tasks, includeCompleted: _vm.includeCompleted },
        on: { action: _vm.handleEdit },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(slot) {
                return [
                  _vm.shouldShowStartButton(slot.task)
                    ? _c(
                        "button",
                        {
                          staticClass: "task-button start",
                          on: {
                            click: function($event) {
                              return _vm.handleStart(slot.task)
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "play"], size: "lg" }
                          })
                        ],
                        1
                      )
                    : _vm.shouldShowStopButton(slot.task)
                    ? _c(
                        "button",
                        {
                          staticClass: "task-button stop",
                          on: {
                            click: function($event) {
                              return _vm.handleStop(slot.task)
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "stop"], size: "lg" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c("numeric-value-widget", {
                        attrs: {
                          value: slot.task.duration,
                          min: 0,
                          increment: _vm.durationIncrement,
                          parser: _vm.parseDuration,
                          formatter: _vm.formatDuration,
                          extraButtonClasses: "task-button"
                        },
                        on: {
                          input: function(newDuration) {
                            return _vm.handleDurationUpdate(
                              slot.task,
                              newDuration
                            )
                          }
                        }
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "task-button",
                          on: {
                            click: function($event) {
                              return _vm.handleEdit(slot.task)
                            }
                          }
                        },
                        [_c("font-awesome-icon", { attrs: { icon: "edit" } })],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "task-button delete",
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(slot.task)
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "trash-alt"] }
                          })
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "task-button done",
                          on: {
                            click: function($event) {
                              return _vm.handleDone(slot.task)
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "check-square"] }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          false,
          1212808781
        )
      })
    : _c("img", { attrs: { src: _vm.loader, alt: _vm.$t("loading") } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }