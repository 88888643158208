var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { attrs: { id: "notifs" } },
      [
        _c("toast-notification", { attrs: { toasts: _vm.toasts } }),
        _c("sync-indicator")
      ],
      1
    ),
    _c(
      "nav",
      { attrs: { id: "debug-panel" } },
      [
        _c("time-period-selector", {
          attrs: { value: _vm.dates },
          on: { input: _vm.changeDates }
        }),
        _c("label", { attrs: { for: "debug-switch-include-completed" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.includeCompleted,
                expression: "includeCompleted"
              }
            ],
            attrs: { type: "checkbox", id: "debug-switch-include-completed" },
            domProps: {
              checked: Array.isArray(_vm.includeCompleted)
                ? _vm._i(_vm.includeCompleted, null) > -1
                : _vm.includeCompleted
            },
            on: {
              change: function($event) {
                var $$a = _vm.includeCompleted,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.includeCompleted = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.includeCompleted = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.includeCompleted = $$c
                }
              }
            }
          }),
          _vm._v(" Include completed ")
        ])
      ],
      1
    ),
    _c(
      "main",
      [
        _c(
          "section",
          { attrs: { id: "task-column" } },
          [
            _c("quick-add-task", {
              attrs: { categories: _vm.allCategories },
              on: {
                "start-new": _vm.handleQuickStart,
                "start-task": _vm.handleStart,
                "add-task": _vm.handleAddTask
              }
            }),
            _c(
              "div",
              { attrs: { id: "all-tasks" } },
              [
                _c("h3", [_vm._v("Other")]),
                _c("task-list", {
                  attrs: {
                    tasks: _vm.filteredTasks,
                    includeCompleted: _vm.includeCompleted,
                    loader: "/img/loader.gif"
                  },
                  on: {
                    edit: _vm.handleEdit,
                    delete: _vm.handleDelete,
                    update: _vm.handleSave,
                    done: _vm.handleMarkDone,
                    start: _vm.handleStart,
                    stop: _vm.handleStop
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "actions" }, [
              _c(
                "button",
                { staticClass: "add-task", on: { click: _vm.handleAddTask } },
                [
                  _c("font-awesome-icon", { attrs: { icon: "plus-square" } }),
                  _vm._v(" " + _vm._s(_vm.$t("new_task")) + " ")
                ],
                1
              )
            ])
          ],
          1
        ),
        _c("section", { attrs: { id: "work-column" } }, [
          _c(
            "div",
            { attrs: { id: "running" } },
            [
              _c("task-timer-list", {
                attrs: { tasks: _vm.runningTasks, loader: "/img/loader.gif" },
                on: { update: _vm.handleSave }
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { id: "planned-today" } },
            [
              _c("h3", [_vm._v("Today")]),
              _c("task-list-planned", {
                attrs: {
                  hasStartButton: true,
                  tasks: _vm.plannedToday,
                  includeCompleted: _vm.includeCompleted,
                  loader: "/img/loader.gif"
                },
                on: {
                  edit: _vm.handleEdit,
                  delete: _vm.handleDelete,
                  update: _vm.handleSave,
                  done: _vm.handleMarkDone,
                  start: _vm.handleStart,
                  stop: _vm.handleStop
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { id: "planned-tomorrow" } },
            [
              _c("h3", [_vm._v("Tomorrow")]),
              _c("task-list-planned", {
                attrs: {
                  tasks: _vm.plannedTomorrow,
                  includeCompleted: _vm.includeCompleted,
                  loader: "/img/loader.gif"
                },
                on: {
                  edit: _vm.handleEdit,
                  delete: _vm.handleDelete,
                  update: _vm.handleSave,
                  done: _vm.handleMarkDone
                }
              })
            ],
            1
          )
        ]),
        _vm.editingTask
          ? _c("task-editor", {
              attrs: { task: _vm.editedItem },
              on: { cancel: _vm.handleCancelSave, save: _vm.handleSave }
            })
          : _vm._e(),
        _vm.editingTimeEntry
          ? _c("time-entry-editor", {
              attrs: { timeEntry: _vm.editedItem },
              on: { cancel: _vm.handleCancelSave, save: _vm.handleSave }
            })
          : _vm._e(),
        _vm.question
          ? _c("confirmation-popup", {
              attrs: {
                context: _vm.confirmationContext,
                message: _vm.question
              },
              on: { no: _vm.handleNo, yes: _vm.handleYes }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }