<template>
  <ul class="summary-stats">
    <li
      v-for="category in lv1Categories"
      @mouseenter="select(category.name)"
      @mouseleave="deselect(category.name)"
      @click.stop="toggle(category.name)"
      :key="categoryKey(category.name, level)" >
      <div :class="labelClass(category)">
        <div>{{ category.name[category.name.length-1] }}</div>
        <div>{{ durationToString(category.total) }}</div>
      </div>
      <div class="category-children">
        <stat-summary
          :parent="category.name"
          :tasks="tasks"
          :level="level+1"
          :selected="selected"
          :sticky="sticky"
          @select="select"
          @deselect="deselect"
          />
      </div>
    </li>
  </ul>
</template>

<script>
import { arrayStartsWith, arrayEquals } from '@/core/utils/arrayUtils';
import { durationToString } from '@/core/utils/dateUtils';

export default {
  name: 'stat-summary',
  components: {
    StatSummary: this,
  },
  props: {
    level: {
      type: Number,
      default: 0,
    },
    tasks: Array,
    parent: {
      type: Array,
      default: () => [],
    },
    selected: {
      default: null,
    },
    sticky: {
      default: null,
    },
  },
  methods: {
    categoryKey(category, level) {
      return `cat-${category.join('\u00bb')}-${level}`;
    },
    labelClass(category) {
      const cls = ['category-data', `level${this.level}`];
      if (arrayEquals(this.selected, category.name)) {
        cls.push('selected');
      }
      return cls;
    },
    durationToString,
    select(datum, sticky) {
      this.$emit('select', datum, sticky);
    },
    deselect(datum, sticky) {
      this.$emit('deselect', datum, sticky);
    },
    toggle(datum) {
      if (this.selected && this.sticky) {
        this.deselect(datum, true);
      } else {
        this.select(datum, true);
      }
    },
  },
  computed: {
    lv1Categories() {
      return this.tasks.filter(t => t.name.length === this.level+1
        && arrayStartsWith(t.name, this.parent));
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}
li:nth-child(2n) {
  border-bottom-color: #aaa;
}
.selected {
  color: #dd0c08;
}
.category-data {
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom-color: #ccc;
  border-bottom-style: dotted;
  border-bottom-width: 1px
}
.category-data:nth-child(2n) {
  border-bottom-style: dashed;
}

.level0 {
  font-weight: bold;
  font-size: 16pt;
  border-bottom-width: 3px;
  margin-top: 0.5em;
}
.level1 {
  font-weight: bold;
  font-size: 14pt;
  border-bottom-width: 2px;
  margin-top: 0.2em;
}
.level2 {
  font-weight: bold;
  font-size: 12pt;
}
.level3,
.level4,
.level5,
.level6,
.level7,
.level8,
.level9 {
  font-weight: normal;
  font-size: 10pt;
}
</style>
