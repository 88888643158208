var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag-input" },
    [
      _vm._l(_vm.value, function(tag, idx) {
        return _c(
          "div",
          { key: "tag-" + tag + "-" + idx, staticClass: "tag" },
          [_vm._v(_vm._s(tag))]
        )
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.userInput,
            expression: "userInput"
          }
        ],
        attrs: {
          id: _vm.id,
          list: _vm.id + "-datalist",
          placeholder: _vm.placeholder
        },
        domProps: { value: _vm.userInput },
        on: {
          keyup: [
            function($event) {
              _vm.datalist = _vm.suggest(_vm.userInput)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k(
                  $event.keyCode,
                  "backspace",
                  undefined,
                  $event.key,
                  undefined
                )
              ) {
                return null
              }
              return _vm.handleBackspace($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.select($event)
            },
            function($event) {
              if (!$event.type.indexOf("key") && $event.keyCode !== 188) {
                return null
              }
              return _vm.select($event)
            }
          ],
          focus: function($event) {
            _vm.datalist = _vm.suggest(_vm.userInput)
          },
          blur: _vm.select,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.userInput = $event.target.value
          }
        }
      }),
      _c(
        "datalist",
        { attrs: { id: _vm.id + "-datalist" } },
        _vm._l(_vm.datalist, function(entry) {
          return _c("option", { key: "datalist-item-" + entry }, [
            _vm._v(_vm._s(entry))
          ])
        }),
        0
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }