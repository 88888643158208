/* eslint-disable class-methods-use-this */
// re-enable if manager should save created/changed entries
import Task from '@/core/models/Task';
import TimeEntry from '@/core/models/TimeEntry';
import PlannedTask from '@/core/models/PlannedTask';

import { assertInstance } from '@/core/utils/validation';

const REPO = Symbol('Task repository');

class TaskManager {
  constructor(repository) {
    this[REPO] = repository;
  }

  get repository() {
    return this[REPO];
  }

  init(options) {
    return this.repository.load(options);
  }

  newTask(data) {
    return new Task(data);
  }

  get(_id) {
    return this.repository.get(_id);
  }

  async find(options) {
    if (options.name) {
      return this.repository.findTaskByName(options.name);
    }
    throw new Error(`Find called with options that did not result in a valid search: ${JSON.stringify(options)}`);
  }

  save(savable) {
    return this.repository.save(savable);
  }

  async delete(savable) {
    await this.repository.delete(savable);
  }

  plan(task, date, duration) {
    assertInstance(task, Task);
    return new PlannedTask(task, { date, duration });
  }

  startTask(task) {
    assertInstance(task, Task);
    const te = new TimeEntry(task);
    // eslint-disable-next-line no-param-reassign
    task.isRunning = true;
    return te;
  }

  markPlannedTaskCompleted(plannedTask) {
    assertInstance(plannedTask, PlannedTask);
    // eslint-disable-next-line no-param-reassign
    plannedTask.done = true;
    return this.repository.save(plannedTask);
  }

  unmarkPlannedTaskCompleted(plannedTask) {
    assertInstance(plannedTask, PlannedTask);
    // eslint-disable-next-line no-param-reassign
    plannedTask.done = false;
    return this.repository.save(plannedTask);
  }

  markTaskCompleted(task) {
    assertInstance(task, Task);
    task.timeEntries.forEach(te => this.stop(te));
    // eslint-disable-next-line no-param-reassign
    task.done = true;
    return this.repository.save(task);
  }

  unmarkTaskCompleted(task) {
    assertInstance(task, Task);
    // eslint-disable-next-line no-param-reassign
    task.done = false;
    return this.repository.save(task);
  }

  stop(timeEntry) {
    assertInstance(timeEntry, TimeEntry);
    if (timeEntry.end) {
      // use earlier stop time
      // eslint-disable-next-line no-param-reassign
      timeEntry.end = new Date(Math.min(Number(timeEntry.end), Date.now()));
    } else {
      // eslint-disable-next-line no-param-reassign
      timeEntry.end = new Date();
    }
    // eslint-disable-next-line no-param-reassign
    timeEntry.task.isRunning = false;
  }
}

export default TaskManager;
