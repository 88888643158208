import { priority } from '@/core/constants';
import TaskDAO from './dao/TaskDAO';

class Task {
  constructor(dao = null) {
    const d = dao || {};
    this._id = d._id || new Date().toISOString();
    this.category = d.category || [];
    this.name = d.name || 'New task';
    this.priority = d.priority || priority.NORMAL;
    this.tags = d.tags || [];
    this.done = !!d.done;
    Object.defineProperty(this, 'plannedTasks', {
      enumerable: true,
      configurable: true,
      writable: false,
      value: [],
    });
    Object.defineProperty(this, 'timeEntries', {
      enumerable: true,
      configurable: true,
      writable: false,
      value: [],
    });
  }

  get createdAt() {
    return new Date(this._id);
  }

  createdBetween(start, end) {
    const d = this.createdAt;
    return d >= start && d < end;
  }

  toDAO() {
    return new TaskDAO(this);
  }

  toString() {
    return `${this.name} (${this.category.join('>')})`;
  }
}

export default Task;
