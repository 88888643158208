<template>
  <input :id="id" @change="handleChange" :value="dateStr" />
</template>

<script>

import { today, tomorrow } from '@/core/utils/dateUtils';

const dateTimeRegexp = /^\s*(?:(\d{4})-(\d{1,2})-(\d{1,2})[ T])?(\d{1,2}):(\d{2})(?::(\d{2}))?(?: ?[ap]m)? ?(?:Z|GMT|UTC)?\s*$/i;

function zeroPad(number) {
  const n = Number(number);
  if (n<10) return `0${n}`;
  return n.toString();
}

const dateParsers = [
  {
    regexp: /^\s*\d{4}\s*$/,
    parse: (dateStr) => {
      const h = Number(dateStr.substring(0, 2));
      const m = Number(dateStr.substring(2, 4));
      const date = new Date();
      date.setHours(h);
      date.setMinutes(m);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    },
  }, {
    regexp: dateTimeRegexp,
    parse: (dateStr) => {
      const now = new Date();
      const values = dateTimeRegexp.exec(dateStr);
      const str = dateStr.toLowerCase();
      let [, y, m, d, h, i, s] = values;
      const pm = str.includes('pm');
      const utc = str.includes('z') || str.includes('utc') || str.includes('gmt');
      y = y || now[utc ? 'getUTCFullYear' : 'getFullYear']();
      m = zeroPad(m || (1 + now[utc ? 'getUTCMonth' : 'getMonth']()));
      d = zeroPad(d || now[utc ? 'getUTCDate' : 'getDate']());
      if (pm) h = Number(h)+12;
      h = zeroPad(h);
      i = zeroPad(i);
      s = zeroPad(s || 0);
      return new Date(`${y}-${m}-${d}T${h}:${i}:${s}${utc?'Z':''}`);
    },
  },
];

function formatDate(date) {
  const hour = zeroPad(date.getHours());
  const minute = zeroPad(date.getMinutes());
  const timeString = `${hour}:${minute}`;
  if (date < today() || date >= tomorrow()) {
    const year = date.getFullYear();
    const month = zeroPad(date.getMonth() + 1);
    const day = zeroPad(date.getDate());
    return `${year}-${month}-${day} ${timeString}`;
  }
  return timeString;
}

function parseDate(date) {
  const parser = dateParsers.find(p => p.regexp.test(date));
  return parser ? parser.parse(date) : null;
}

export default {
  name: 'time-input',
  props: {
    value: {
      id: {
        type: String,
        default: 'time-input-id',
      },
      type: Date,
      required: true,
    },
  },
  computed: {
    dateStr() {
      return formatDate(this.value);
    },
  },
  methods: {
    handleChange(event) {
      const date = parseDate(event.target.value);
      if (Number(date)) {
        this.$emit('input', date);
      }
    },
  },
};
</script>
