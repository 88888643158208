import VueI18n from 'vue-i18n';
import VueTimers from 'vue-timers';
import VueDatepicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';

import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import './registerServiceWorker';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import PouchDB from 'pouchdb-browser';

import TaskManagerPlugin from '@/plugins/TaskManager';
import AuthManagerPlugin from '@/plugins/AuthManager';
import ToastNotificationPlugin from '@/plugins/ToastNotification';
import lang from '@/lang';

import DefaultAuthProvider from '@/core/auth/DefaultAuthProvider';
import PouchDbAdapter from '@/core/db/PouchDbAdapter';
import factory from '@/core/models/factory';
import router from './router';
import App from './App.vue';
import debug from '@/debug';

export default async function initApp(keycloak) {
  Vue.config.productionTip = false;

  Vue.use(VueI18n);
  Vue.use(VueTimers);

  const authProviderOptions = {
    dbUri: 'https://db.timefly.io',
    backendUri: 'https://api.timefly.io',
    keycloak,
  };

  const APP = Symbol('TimeFly App');

  const dbAdapter = new PouchDbAdapter(null);
  dbAdapter.on('error', APP, err => console.error('dbAdapter error', err));
  Vue.use(TaskManagerPlugin, { dbAdapter, factory });
  Vue.use(AuthManagerPlugin, new DefaultAuthProvider(authProviderOptions));
  Vue.use(ToastNotificationPlugin);

  Vue.$auth.loggedInUser = keycloak && keycloak.idTokenParsed ? keycloak.idTokenParsed.preferred_username : 'Anonymous';

  Vue.$auth.on('login', APP, (login, remoteDbName) => {
    debug('OnLogin', login, remoteDbName);
    const userDb = new PouchDB(login);
    const replicatePromise = Vue.$auth.offlineDb
      ? new Promise((resolve, reject) => {
        const localDb = new PouchDB(Vue.$auth.offlineDb);
        localDb.replicate.to(userDb)
          .on('complete', () => localDb.destroy().then(resolve).catch(reject))
          .on('error', reject);
      })
      : Promise.resolve();
    // FIXME: find a better way to handle keycloak user db name
    // eslint-disable-next-line no-param-reassign
    remoteDbName = `db-${keycloak.tokenParsed.sub}`;
    const remoteDb = new PouchDB(`${authProviderOptions.dbUri}/${remoteDbName}`, {
      fetch(url, opts) {
        opts.headers.set('Authorization', `Bearer ${keycloak.token}`);
        return PouchDB.fetch(url, opts);
      },
    });
    return dbAdapter.reset(userDb)
      .then(replicatePromise)
      .then(() => Vue.$auth.stopOfflineMode())
      .then(() => dbAdapter.startSync(remoteDb));
  });

  Vue.$auth.on('login-offline', APP, dbName => new Promise((resolve, reject) => {
    debug('OnLoginOffline');
    const localDb = new PouchDB(dbName);
    dbAdapter.reset(localDb).then(resolve).catch(reject);
  }));

  if (Vue.$auth.isLoggedIn) {
    await Vue.$auth.refresh();
  }


  // on logout, &c

  Vue.component('font-awesome-icon', FontAwesomeIcon);
  Vue.component('vue-datepicker', VueDatepicker);

  const i18n = new VueI18n({
    locale: 'en',
    messages: lang,
  });

  if (process.env.NODE_ENV === 'dev') {
    Vue.$auth.startOfflineMode();
  }

  Sentry.init({
    Vue,
    dsn: 'https://029ce58b38af472093dd82d9496f6c87@o1359057.ingest.sentry.io/6646441',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'timefly.io', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  new Vue({
    i18n,
    router,
    render: h => h(App),
  }).$mount('#app');
}
