import TaskRepository from '@/core/db/TaskRepository';
import TaskManager from '@/core/managers/TaskManager';

if (process.env.VUE_APP_WITH_DEBUG && process.env.NODE_ENV !== 'test') {
  const origSave = TaskRepository.prototype.save;
  TaskRepository.prototype.save = function save(...args) {
    // args[0] is an object to save, other args might be added in the future
    // eslint-disable-next-line no-param-reassign
    args[0].debug = {
      appVersion: process.env.VUE_APP_VERSION || '(unknown)',
      userAgent: navigator.userAgent,
      ts: Date(),
    };
    return origSave.apply(this, args);
  };
}

const taskManagerPlugin = {
  install(Vue, options) {
    if (!options.dbAdapter || !options.factory) {
      throw new Error('Options must include dbAdapter and factory.');
    }
    if (Vue.prototype.$manager) return; // prevent accidental double-install
    const taskRepository = new TaskRepository(options.dbAdapter, options.factory);
    const manager = new TaskManager(taskRepository);
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$manager = manager;
  },
};

export default taskManagerPlugin;
