<template>
  <div>
    <h4 class="planned-tasks">{{ $t('task_planned_tasks_header') }}</h4>
    <ul v-if="plannedTasks && plannedTasks.length">
      <planned-task-entry-list-item
        v-for="pt in plannedTasks"
        @update="handleUpdate"
        :plannedTask="pt"
        :key="`planned-task-${pt._id}`">
        <template v-slot:default="slot">
          <button
            :disabled="slot.plannedTask.date < today"
            class="task-button"
            @click="handleDelete(slot.plannedTask)">
            <font-awesome-icon icon="trash-alt" />
          </button>
        </template>
      </planned-task-entry-list-item>
    </ul>
    <p v-else-if="plannedTasks" class="empty-placeholder">
      {{ $t('no_planned_tasks') }}
    </p>
  </div>
</template>

<script>
import PlannedTaskEntryListItem from '@/components/PlannedTaskEntryListItem.vue';
import PlannedTask from '@/core/models/PlannedTask';
import { today } from '@/core/utils/dateUtils';

export default {
  components: {
    PlannedTaskEntryListItem,
  },
  props: {
    plannedTasks: {
      required: true,
      validator(value) {
        if (value === null || value === undefined) return true;
        return Array.isArray(value) && !value.find(t => !(t instanceof PlannedTask));
      },
    },
  },
  methods: {
    handleDelete(plannedTask) {
      this.$emit('delete', plannedTask);
    },
    handleUpdate(plannedTask) {
      this.$emit('update', plannedTask);
    },
  },
  computed: {
    today,
  },
};
</script>

<style lang="scss" scoped>
ul {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
h4 {
  font-size: 12pt;
  font-weight: bold;
  text-decoration: none;
  display: block;
  border-bottom: 3px double #333;
  margin: 0 0 0.5em 0;
  text-align: left;
}
.empty-placeholder {
  color: #333;
  font-size: 10pt;
  font-style: italic;
  text-align: center;
}
</style>
