import { startOfDay } from '@/core/utils/dateUtils';

function taskIdDayHash(obj) {
  const d = startOfDay(obj.date);
  const tid = obj.taskId;
  return `${tid}@${d}`;
}

function sumByDateReducer(acc, obj) {
  const hash = taskIdDayHash(obj);
  if (!acc[hash]) {
    acc[hash] = obj; // eslint-disable-line no-param-reassign
  } else {
    acc[hash].duration += obj.duration; // eslint-disable-line no-param-reassign
  }
  return acc;
}

export function sumByDate(tasks) {
  return Object.values(tasks.reduce(sumByDateReducer, {}));
}

export function hashFnv32a(str, seed) {
  let hval = (seed === undefined) ? 0x811c9dc5 : seed;
  for (let i = 0; i < str.length; i++) {
    hval ^= str.charCodeAt(i); // eslint-disable-line no-bitwise
    // eslint-disable-next-line no-bitwise
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  // eslint-disable-next-line no-bitwise
  return hval >>> 0;
}

/**
 * returns a hue for given string.
 * Used for coloring top-level categories.
 * @param {String} string to hash
 * @returns {Number} hue value for given string
 */
export function hue(str) {
  // eslint-disable-next-line no-bitwise
  return (hashFnv32a(str) / (0xffffffff>>>0) * 360)>>>0;
}
