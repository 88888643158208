<template>
  <g class="label" :transform="`translate(${x}, ${y})`">
    <rect
      :x="-realWidth/2"
      :y="-height/2"
      :width="realWidth"
      :height="height" />
    <text
      ref="label"
      class="label"
      text-anchor="middle"
      dominant-baseline="middle"
      x="0" :y="height * -0.2">{{ label }}</text>
    <text
      class="value"
      text-anchor="middle"
      dominant-baseline="middle"
      x="0" :y="height * 0.2">{{ value }}</text>
  </g>
</template>

<script>
export default {
  data() {
    return {
      realWidth: 100,
    };
  },
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 62,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
  },
  mounted() {
    const tw = this.$refs.label.getBBox().width + 20;
    this.realWidth = Math.max(this.width, tw);
  },
};
</script>

<style scoped>
g, g * {
  pointer-events: none;
}
g.label {
  stroke-width: 2px;
  stroke: "#ccc";
  fill: rgba(10, 10, 10, 70%);
}
.label {
  stroke-width: 0;
  fill: #ccc;
  color: #ccc;
  font-size: 18px;
  font-weight: bold;
}
.value {
  stroke-width: 0;
  fill: #aaa;
  color: #aaa;
  font-size: 14px;
  font-weight: normal;
}
</style>
