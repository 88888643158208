<template>
  <ul>
    <task-list-item
      v-for="task in sortedTasks"
      @update="handleUpdate"
      @edit="handleEdit"
      @delete="handleDelete"
      :task="task"
      :showCategory="showCategory(task)"
      :topCategoryChanged="topCategoryChanged(task)"
      :key="task._id">
      <slot :task="task" />
    </task-list-item>
  </ul>
</template>

<script>
import { compareByArray, arrayEquals } from '@/core/utils/arrayUtils';

import TaskListItem from '@/components/TaskListItem.vue';

export default {
  name: 'task-list-base',
  components: {
    TaskListItem,
  },
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    includeCompleted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    filteredTasks() {
      if (this.includeCompleted) return this.tasks;
      return this.tasks.filter(t => !t.done);
    },
    sortedTasks() {
      return this.filteredTasks.slice().sort((a, b) => compareByArray(a, b, obj => obj.category));
    },
  },
  methods: {
    handleEdit(item) {
      this.$emit('edit', item);
    },
    handleDelete(item) {
      this.$emit('delete', item);
    },
    handleUpdate(item) {
      this.$emit('update', item);
    },
    showCategory(task) {
      const idx = this.sortedTasks.indexOf(task);
      if (idx > 0) {
        return !arrayEquals(this.sortedTasks[idx-1].category, task.category);
      }
      return true;
    },
    topCategoryChanged(task) {
      const idx = this.sortedTasks.indexOf(task);
      if (idx > 0) {
        const prevCategory = this.sortedTasks[idx-1].category;
        const { category } = task;
        if (!prevCategory.length && category.length) return true;
        if (prevCategory.length && !category.length) return true;
        return prevCategory[0] !== category[0];
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
