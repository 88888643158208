<template>
  <div :class="classes">
    {{ $t('sync_in_progress') }}
  </div>
</template>

<script>

export default {
  props: {
    extraClasses: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      syncActive: false,
    };
  },
  mounted() {
    this.$manager.repository.onSyncStateChange(this, this.syncStateChanged);
  },
  beforeUnmount() {
    this.$manager.repository.offSyncStateChange(this);
  },
  methods: {
    syncStateChanged(state, direction) { // eslint-disable-line no-unused-vars
      this.syncActive = state === 'resume';
    },
  },
  computed: {
    classes() {
      const cls = ['sync-in-progress', ...this.extraClasses.split(' ')];
      if (!this.syncActive) {
        cls.push('hidden');
      }
      return cls;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/scss/_colors.scss';

.sync-in-progress {
  margin: 0 auto;
  padding: 2mm;
  font-size: 10pt;
  background-color: gray($color-primary);
}
.hidden {
  visibility: hidden;
}
</style>
