var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "numwidget" }, [
    _c(
      "button",
      {
        class: _vm.decClasses,
        attrs: { disabled: _vm.value <= _vm.min },
        on: { click: _vm.dec }
      },
      [_vm._v(" - ")]
    ),
    _vm.editing
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.strDuration,
              expression: "strDuration"
            }
          ],
          domProps: { value: _vm.strDuration },
          on: {
            blur: _vm.stopEdit,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.strDuration = $event.target.value
            }
          }
        })
      : _c("span", { on: { click: _vm.startEdit } }, [
          _vm._v(_vm._s(_vm.formatter(_vm.value)))
        ]),
    _c(
      "button",
      {
        class: _vm.incClasses,
        attrs: { disabled: _vm.value >= _vm.max },
        on: { click: _vm.inc }
      },
      [_vm._v(" + ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }