import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from './views/Dashboard.vue';
import Planning from './views/Planning.vue';
import Stats from './views/Stats.vue';

Vue.use(Router);

const publicRoutes = [
  'about',
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/plan',
      name: 'planning',
      component: Planning,
    },
    {
      path: '/stats',
      name: 'stats',
      component: Stats,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const noAnon = Vue.$auth.isLoggedIn || Vue.$auth.offlineDb;
  if (publicRoutes.includes(to.name) || noAnon) {
    next();
  } else {
    // we've been logged out, reload to trigger keycloak login
    window.document.location.reload();
  }
});

export default router;
