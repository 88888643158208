var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "time-entry" }, [
    _c("div", { staticClass: "task-name" }, [
      _vm._v(_vm._s(_vm.timeEntry.name) + " ")
    ]),
    _vm.timeEntry
      ? _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))])
      : _vm._e(),
    !_vm.timeEntry.end
      ? _c(
          "button",
          { staticClass: "stop", on: { click: _vm.stop } },
          [
            _c("font-awesome-icon", {
              staticClass: "button",
              attrs: { icon: "stop" }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }