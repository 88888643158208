<template>
  <nav :class="navClasses">
    <button v-if="isMobile" @click="toggle" class="nav-toggle">
      <font-awesome-icon icon="bars" />
    </button>
    <div class="collapse-target">
      <slot />
    </div>
  </nav>
</template>

<script>
export default {
  name: 'nav-bar',

  props: {
    addClasses: {
      type: String,
      required: false,
      default: '',
    },
    minWidth: {
      required: true,
      validator(v) {
        return Number.isFinite(Number(v));
      },
    },
  },

  data() {
    return {
      isMobile: false,
      collapsed: false,
    };
  },

  mounted() {
    this.collapsed = this.isMobileWidth();
    this.isMobile = this.collapsed;
    window.addEventListener('resize', () => {
      this.refresh();
    });
  },

  methods: {
    toggle() {
      this.collapsed = !this.collapsed;
    },
    refresh() {
      if (!this.collapsed && this.isMobileWidth()) {
        this.collapsed = true;
        this.isMobile = true;
      } else if (this.collapsed && !this.isMobileWidth()) {
        this.collapsed = false;
        this.isMobile = false;
      }
    },
    isMobileWidth() {
      return this.$el.offsetWidth < Number(this.minWidth);
    },
  },

  computed: {
    navClasses() {
      return this.collapsed ? [...this.additionalClasses, 'collapsed'] : this.additionalClasses;
    },
    additionalClasses() {
      return this.addClasses.split(' ');
    },
  },
};
</script>

<style scoped lang="scss">
.collapsed>.collapse-target {
  display: none;
}

</style>
