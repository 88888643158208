var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.cancel($event)
        },
        keypup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)
          ) {
            return null
          }
          return _vm.cancel($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-window",
          on: {
            keyup: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "escape",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                return _vm.cancel($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.save($event)
              }
            ]
          }
        },
        [
          _c("label", { attrs: { for: "task-name-input" } }, [
            _vm._v(_vm._s(_vm.$t("task_name")) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.task.name,
                  expression: "task.name"
                }
              ],
              attrs: { id: "task-name-input" },
              domProps: { value: _vm.task.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.task, "name", $event.target.value)
                }
              }
            })
          ]),
          _c(
            "label",
            { attrs: { for: "task-category-input" } },
            [
              _vm._v(_vm._s(_vm.$t("task_category")) + " "),
              _c("tag-selector", {
                attrs: {
                  id: "task-category-input",
                  suggest: _vm.suggestCategory
                },
                model: {
                  value: _vm.task.category,
                  callback: function($$v) {
                    _vm.$set(_vm.task, "category", $$v)
                  },
                  expression: "task.category"
                }
              })
            ],
            1
          ),
          _c("label", { attrs: { for: "task-priority-input" } }, [
            _vm._v(_vm._s(_vm.$t("task_priority")) + " "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.task.priority,
                    expression: "task.priority"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.task,
                      "priority",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.priorities, function(p) {
                return _c(
                  "option",
                  {
                    key: "option-priority-" + p,
                    domProps: { value: _vm.numPriority(p) }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("task_priority_" + p)) + " ")]
                )
              }),
              0
            )
          ]),
          _c(
            "label",
            { attrs: { for: "task-tags-input" } },
            [
              _vm._v(_vm._s(_vm.$t("task_tags")) + " "),
              _c("tag-selector", {
                attrs: { id: "tag-selector-input" },
                model: {
                  value: _vm.task.tags,
                  callback: function($$v) {
                    _vm.$set(_vm.task, "tags", $$v)
                  },
                  expression: "task.tags"
                }
              })
            ],
            1
          ),
          _c("button", { staticClass: "save", on: { click: _vm.save } }, [
            _vm._v(_vm._s(_vm.$t("save")))
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }