var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { class: _vm.navClasses }, [
    _vm.isMobile
      ? _c(
          "button",
          { staticClass: "nav-toggle", on: { click: _vm.toggle } },
          [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "collapse-target" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }