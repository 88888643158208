export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

export function startOfDay(date) {
  const d = new Date(date);
  d.setUTCHours(0, 0, 0, 0);
  return d;
}

export function today() {
  return startOfDay(Date.now());
}

export function yesterday() {
  return startOfDay(Date.now() - DAY);
}

export function tomorrow() {
  return startOfDay(Date.now() + DAY);
}

export function asatte() {
  return startOfDay(Date.now() + 2*DAY);
}

/**
 * Returns start of week the given date is in.
 * with optional week start offset (by default 1 = monday).
 */
export function startOfWeek(date, weekdayOffset = 1) {
  let days = date.getUTCDay() - weekdayOffset;
  if (days < 0) days += 7;
  return startOfDay(date.getTime() - days * DAY);
}

export function startOfMonth(date) {
  return startOfDay(date.getTime() - (date.getUTCDate() - 1) * DAY);
}

export function isoDate(date) {
  const d = new Date(date);
  return d.toISOString();
}

export function durationToString(ms) {
  const h = Math.floor(ms / HOUR);
  const m = Math.floor((ms - h*HOUR)/MINUTE);
  const s = Math.floor((ms - h*HOUR - m*MINUTE)/SECOND);

  let strDuration = '0';
  if (h > 0 && s > 0) {
    strDuration = `${h}h ${m}m ${s}s`;
  } else if (h > 0 && m > 0) {
    strDuration = `${h}h ${m}m`;
  } else if (h > 0) {
    strDuration = `${h}h`;
  } else if (m > 0 && s > 0) {
    strDuration = `${m}m ${s}s`;
  } else if (m > 0) {
    strDuration = `${m}m`;
  } else if (s > 0) {
    strDuration = `${s}s`;
  }
  return strDuration;
}

export function parseDuration(str) {
  const formatRegex = /^(?:(\d+)d)?\s*(?:(\d+)h)?\s*(?:(\d+)m)?\s*(?:(\d+)s)?\s*(\d*)(?:ms)?\s*$/;
  const parsed = formatRegex.exec(str);
  if (parsed === null) return parsed;
  const ms = Number(parsed[5]) || 0;
  const s = Number(parsed[4]) || 0;
  const m = Number(parsed[3]) || 0;
  const h = Number(parsed[2]) || 0;
  const d = Number(parsed[1]) || 0;
  return d*DAY + h*HOUR + m*MINUTE + s*SECOND + ms;
}
