import PlannedTaskDAO from './dao/PlannedTaskDAO';

import { durationToString, HOUR, tomorrow } from '@/core/utils/dateUtils';

const PARENT = Symbol('parent task');

class PlannedTask {
  constructor(task, dao = {}) {
    this[PARENT] = task;
    this.date = dao.date ? new Date(dao.date) : tomorrow();
    const hasDuration = dao.duration || dao.duration === 0;
    this.duration = hasDuration ? Number(dao.duration) : HOUR;
    this.tags = [...new Set([...(task.tags||[]), ...(dao.tags||[])])];
    // WARNING: avoid chars that are in date strings as separator
    this._id = dao._id || `${task._id}*${new Date().toISOString()}`;
    this.done = dao.done || false;
    this.updateParent(dao._deleted);
    Object.defineProperty(this, 'task', {
      enumerable: true,
      configurable: true,
      writable: false,
      value: task,
    });
  }

  get taskId() {
    return this._id.split('*')[0];
  }

  get createdAt() {
    return new Date(this._id.split('*')[1]);
  }

  get name() {
    return this[PARENT].name;
  }

  get category() {
    return this[PARENT].category;
  }

  get priority() {
    return this[PARENT].priority;
  }

  updateParent(deleted) {
    const idx = this[PARENT].plannedTasks.findIndex(pt => pt._id === this._id);
    if (idx >= 0) {
      if (deleted) {
        this[PARENT].plannedTasks.splice(idx, 1);
      } else {
        this[PARENT].plannedTasks.splice(idx, 1, this);
      }
    } else if (!deleted) {
      this[PARENT].plannedTasks.push(this);
    }
  }

  update(related) {
    if (related._id.includes('*')) return;
    this[PARENT] = related;
    const newTags = new Set([...related.tags, ...this.tags]);
    this.tags.splice(0, this.tags.length, ...newTags);
  }

  toDAO() {
    return new PlannedTaskDAO(this);
  }

  toString() {
    return `${this[PARENT].toString()} planned for ${durationToString(this.duration)} @ ${this.date}`;
  }
}
export default PlannedTask;
