var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "planned-task" }, [
    _vm.plannedTask
      ? _c("div", { staticClass: "date" }, [
          _vm._v(_vm._s(_vm.plannedTask.date))
        ])
      : _vm._e(),
    _vm.plannedTask && _vm.plannedTask.date >= _vm.today
      ? _c(
          "div",
          { staticClass: "duration" },
          [
            _c("numeric-value-widget", {
              attrs: {
                value: _vm.plannedTask.duration,
                min: 0,
                increment: _vm.durationIncrement,
                parser: _vm.parseDuration,
                formatter: _vm.formatDuration
              },
              on: { input: _vm.handleDurationUpdate }
            })
          ],
          1
        )
      : _c("div", [_vm._v(_vm._s(_vm.duration))]),
    _vm.plannedTask
      ? _c(
          "div",
          [_vm._t("default", null, { plannedTask: _vm.plannedTask })],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }