var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "summary-stats" },
    _vm._l(_vm.lv1Categories, function(category) {
      return _c(
        "li",
        {
          key: _vm.categoryKey(category.name, _vm.level),
          on: {
            mouseenter: function($event) {
              return _vm.select(category.name)
            },
            mouseleave: function($event) {
              return _vm.deselect(category.name)
            },
            click: function($event) {
              $event.stopPropagation()
              return _vm.toggle(category.name)
            }
          }
        },
        [
          _c("div", { class: _vm.labelClass(category) }, [
            _c("div", [
              _vm._v(_vm._s(category.name[category.name.length - 1]))
            ]),
            _c("div", [_vm._v(_vm._s(_vm.durationToString(category.total)))])
          ]),
          _c(
            "div",
            { staticClass: "category-children" },
            [
              _c("stat-summary", {
                attrs: {
                  parent: category.name,
                  tasks: _vm.tasks,
                  level: _vm.level + 1,
                  selected: _vm.selected,
                  sticky: _vm.sticky
                },
                on: { select: _vm.select, deselect: _vm.deselect }
              })
            ],
            1
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }