var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-datepicker", {
    attrs: { type: "date", range: "", clearable: false },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function(ref) {
          var emit = ref.emit
          return [
            _c("div", { staticClass: "datepicker-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "mx-btn mx-btn-text",
                  on: {
                    click: function($event) {
                      return _vm.today(emit)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("today")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "mx-btn mx-btn-text",
                  on: {
                    click: function($event) {
                      return _vm.thisWeek(emit)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("this_week")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "mx-btn mx-btn-text",
                  on: {
                    click: function($event) {
                      return _vm.thisMonth(emit)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("this_month")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "mx-btn mx-btn-text",
                  on: {
                    click: function($event) {
                      return _vm.last7(emit)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("last_7_days")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "mx-btn mx-btn-text",
                  on: {
                    click: function($event) {
                      return _vm.yesterday(emit)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("yesterday")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "mx-btn mx-btn-text",
                  on: {
                    click: function($event) {
                      return _vm.lastWeek(emit)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("last_week")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "mx-btn mx-btn-text",
                  on: {
                    click: function($event) {
                      return _vm.lastMonth(emit)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("last_month")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "mx-btn mx-btn-text",
                  on: {
                    click: function($event) {
                      return _vm.last28(emit)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("last_4_weeks")) + " ")]
              )
            ])
          ]
        }
      }
    ]),
    model: {
      value: _vm.dates,
      callback: function($$v) {
        _vm.dates = $$v
      },
      expression: "dates"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }