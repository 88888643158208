<template>
  <li class="time-entry">
    <div class="task-name">{{ timeEntry.name }} </div>
    <div v-if="timeEntry" class="time">{{ time }}</div>
    <button
      v-if="!timeEntry.end"
      @click="stop"
      class="stop" >
      <font-awesome-icon class="button" icon="stop" />
    </button>
  </li>
</template>

<script>
import TimeEntry from '@/core/models/TimeEntry';

function zeroPad(v) {
  const strV = v.toString();
  switch (strV.length) {
    case 0:
      return '00';
    case 1:
      return `0${strV}`;
    default:
      return strV;
  }
}

function formatTime(time) {
  const hours = Math.floor(time / 3600000);
  const minutes = Math.floor(time / 60000 - hours * 60);
  const seconds = Math.floor(time / 1000 - minutes*60 - hours*3600);
  return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
}

export default {
  props: {
    timeEntry: {
      type: TimeEntry,
      required: true,
    },
  },
  data() {
    return {
      now: new Date(),
    };
  },
  computed: {
    time() {
      const end = this.timeEntry.end || this.now;
      return formatTime(end - this.timeEntry.start);
    },
  },
  timers: {
    updateTime: {
      time: 100,
      autostart: true,
      repeat: true,
      immediate: true,
    },
  },
  methods: {
    stop() {
      this.$timer.stop('updateTime');
      this.$emit('stop', this.timeEntry);
    },
    updateTime() {
      this.now = new Date();
    },
  },
};
</script>

<style lang="scss" scoped>
.time-entry {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: 1px solid black;
}
.task-name {
  flex-grow: 1;
  text-align: left;
}
.time, .stop, .start {
  flex-grow: 0;
}
.stop, .start {
  margin-left: 5px;
  background: transparent;
  border: 1px solid black;
  border-radius: 5px;
}
</style>
