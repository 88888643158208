var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tasks
    ? _c(
        "ul",
        _vm._l(_vm.tasks, function(task) {
          return _c("task-timer-list-item", {
            key: "timer-" + task._id,
            attrs: { timeEntry: task },
            on: { stop: _vm.handleStop }
          })
        }),
        1
      )
    : _c("img", { attrs: { src: _vm.loader, alt: "$t('loading')" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }