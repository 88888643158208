var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "suggestion-box-wrapper" }, [
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show && _vm.tasks,
            expression: "show && tasks"
          }
        ],
        staticClass: "suggestion-box"
      },
      [
        _vm._l(_vm.tasks, function(task, idx) {
          return _c(
            "suggestion-box-item",
            _vm._b(
              {
                key: task._id,
                attrs: { selected: _vm.isSelected(idx), idx: idx },
                on: {
                  click: _vm.handleClick,
                  mouseSelect: _vm.handleMouseSelect,
                  mouseDeselect: _vm.handleMouseDeselect
                }
              },
              "suggestion-box-item",
              task,
              false
            )
          )
        }),
        _c(
          "li",
          {
            staticClass: "suggestion-add-task",
            on: {
              mousedown: function($event) {
                $event.preventDefault() /* prevent blur */
              },
              click: _vm.createNew
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("new_task")) + " ")]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }