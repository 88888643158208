<template>
  <div
    class="modal-container"
    @click.self="cancel"
    @keyup.escape="cancel"
  >
    <div class="modal-window">
      <label for="time-entry-start">{{ $t("te_start") }}
        <time-input id="time-entry-start" v-model="timeEntry.start" />
      </label>
      <label for="time-entry-end">{{ $t("te_end") }}
        <time-input id="time-entry-end" v-model="timeEntry.end" />
      </label>
      <button @click="save" class="save">{{ $t("save") }}</button>
    </div>
  </div>
</template>

<script>
import TimeEntry from '@/core/models/TimeEntry';
import TimeInput from '@/components/TimeInput.vue';

export default {
  name: 'time-entry-editor',
  components: {
    TimeInput,
  },
  props: {
    timeEntry: {
      type: TimeEntry,
      required: true,
    },
  },
  mounted() {
    document.getElementById('time-entry-start').focus();
  },
  methods: {
    save() {
      this.$emit('save', this.timeEntry);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_colors.scss';
@import '@/scss/_fonts.scss';
@import '@/scss/_modals.scss';

$color-border: scale-color($color-light,  $lightness: -70%, $saturation: -30%);

.modal-container {
  @include modal-container($color-primary);
}

.modal-window {
  @include modal-window($color-light, $color-border);
  padding-bottom: 20px;

  label {
    display: grid;
    grid-template-columns: [labels] 100px [fields] auto;
    align-items: center;
    justify-items: stretch;
  }

  input {
    padding: 7px 5px;
  }

  button {
    @include modal-button(scale-color($color-accent, $lightness: -50%, $saturation: -30%));
  }

  .save {
    background-color: $color-accent;
    padding: 0 5px;
    margin-top: 30px;
  }
}
</style>
