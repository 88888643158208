import TimeEntryDAO from './dao/TimeEntryDAO';

const PARENT = Symbol('parent task');

class TimeEntry {
  constructor(task, dao = {}) {
    const now = new Date();
    this._id = dao._id || `${task._id}*${now.toISOString()}`;
    this.start = new Date(dao.start || now);
    this.tags = [...new Set([...task.tags, ...(dao.tags||[])])];
    this.end = dao.end ? new Date(dao.end) : null;
    this[PARENT] = task;
    this.updateParent(dao._deleted);
  }

  get createdAt() {
    return new Date(this._id.split('*')[1]);
  }

  get taskId() {
    return this._id.split('*')[0];
  }

  get task() {
    return this[PARENT];
  }

  get name() {
    return this.task.name;
  }

  get category() {
    return this.task.category;
  }

  get priority() {
    return this.task.priority;
  }

  updateParent(deleted) {
    const idx = this[PARENT].timeEntries.findIndex(pt => pt._id === this._id);
    if (idx >= 0) {
      if (deleted) {
        this[PARENT].timeEntries.splice(idx, 1);
      } else {
        this[PARENT].timeEntries.splice(idx, 1, this);
      }
    } else if (!deleted) {
      this[PARENT].timeEntries.push(this);
    }
  }

  update(related) {
    if (related._id.includes('*')) return;
    this[PARENT] = related;
    const newTags = new Set([...related.tags, ...this.tags]);
    this.tags.splice(0, this.tags.length, ...newTags);
  }

  toDAO() {
    return new TimeEntryDAO(this);
  }
}

export default TimeEntry;
