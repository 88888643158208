var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "planned-tasks" }, [
      _vm._v(_vm._s(_vm.$t("task_planned_tasks_header")))
    ]),
    _vm.plannedTasks && _vm.plannedTasks.length
      ? _c(
          "ul",
          _vm._l(_vm.plannedTasks, function(pt) {
            return _c("planned-task-entry-list-item", {
              key: "planned-task-" + pt._id,
              attrs: { plannedTask: pt },
              on: { update: _vm.handleUpdate },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(slot) {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "task-button",
                            attrs: {
                              disabled: slot.plannedTask.date < _vm.today
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(slot.plannedTask)
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "trash-alt" }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          }),
          1
        )
      : _vm.plannedTasks
      ? _c("p", { staticClass: "empty-placeholder" }, [
          _vm._v(" " + _vm._s(_vm.$t("no_planned_tasks")) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }