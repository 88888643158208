class TimeEntryDAO {
  constructor(data) {
    Object.defineProperty(this, 'is', {
      enumerable: true,
      writable: false,
      configurable: false,
      value: 'time_entry',
    });
    this._id = data._id;
    this.start = data.start;
    this.end = data.end;
    this.tags = data.tags;
    if (data.debug) {
      this.debug = data.debug;
    }
  }

  get taskId() {
    return this._id.split('*')[0];
  }
}

export default TimeEntryDAO;
