import debug from '@/debug';

const DB = Symbol('Database URI (to be read from login in the future)');
const BACKEND = Symbol('Backend URI');

let keycloak;

class DefaultAuthProvider {
  constructor(options) {
    this[DB] = options.dbUri;
    this[BACKEND] = options.backendUri;
    keycloak = options.keycloak;
  }

  // eslint-disable-next-line class-methods-use-this
  register() {
    keycloak.login();
  }

  // eslint-disable-next-line class-methods-use-this
  getUserDatabase() {
    return `db-${keycloak.idTokenParsed.sub}`;
  }

  // eslint-disable-next-line class-methods-use-this
  login() {
    keycloak.login();
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    keycloak.logout();
  }

  isLoggedIn() {
    debug('isLoggedIn', keycloak);
    return fetch(`${this[DB]}/_session`, {
      method: 'HEAD',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${keycloak.idToken}` },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }).then((response) => {
      switch (response.status) {
        case 200:
          return true;
        case 401:
          return false;
        default:
          throw new Error(`Unrecognized response status: ${response.status}`);
      }
    });
  }
}

export default DefaultAuthProvider;
