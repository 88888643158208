var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    _vm._l(_vm.sortedTasks, function(task) {
      return _c(
        "task-list-item",
        {
          key: task._id,
          attrs: {
            task: task,
            showCategory: _vm.showCategory(task),
            topCategoryChanged: _vm.topCategoryChanged(task)
          },
          on: {
            update: _vm.handleUpdate,
            edit: _vm.handleEdit,
            delete: _vm.handleDelete
          }
        },
        [_vm._t("default", null, { task: task })],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }