<template>
  <div class="numwidget">
    <button :class="decClasses" :disabled="value <= min" @click="dec"> - </button>
    <input v-if="editing" v-model="strDuration" @blur="stopEdit" />
    <span v-else @click="startEdit">{{ formatter(value) }}</span>
    <button :class="incClasses" :disabled="value >= max" @click="inc"> + </button>
  </div>
</template>

<script>
export default {
  name: 'numeric-value-widget',
  props: {
    value: {
      type: Number,
      required: true,
    },
    increment: {
      type: Number,
      default: 1,
    },
    formatter: {
      type: Function,
      default: v => `${v}`,
    },
    parser: {
      type: Function,
      default: v => Number(v),
    },
    min: {
      type: Number,
      default: Number.NEGATIVE_INFINITY,
    },
    max: {
      type: Number,
      default: Number.POSITIVE_INFINITY,
    },
    extraButtonClasses: { },
  },
  data() {
    return {
      strDuration: '',
      editing: false,
    };
  },
  computed: {
    extraClassesArray() {
      if (!this.extraButtonClasses) return [];
      return this.extraButtonClasses instanceof Array
        ? this.extraButtonClasses
        : [this.extraButtonClasses];
    },
    decClasses() {
      return [
        'numwidget-dec-button',
        ...this.extraClassesArray,
      ];
    },
    incClasses() {
      return [
        'numwidget-inc-button',
        ...this.extraClassesArray,
      ];
    },
  },
  methods: {
    inc() {
      this.$emit('input', Math.min(this.max, this.value+this.increment));
    },
    dec() {
      this.$emit('input', Math.max(this.min, this.value-this.increment));
    },
    stopEdit() {
      this.editing = false;
      const val = this.parser(this.strDuration);
      if (val != null) {
        this.$emit('input', Math.max(this.min, Math.min(val)));
      }
    },
    startEdit() {
      this.strDuration = this.formatter(this.value);
      this.editing = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.numwidget {
  display: flex;
  justify-items: space-between;
  align-items: center;
}
</style>
