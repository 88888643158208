<template>
  <div id="app">
    <div v-if="appRoute()" id="nav">
      <nav-bar min-width="800" addClasses="main-nav">
        <ul>
          <router-link :to="{ name: 'dashboard' }" tag="li" exact>
            {{ $t('nav_dashboard') }}
          </router-link>
          <router-link :to="{ name: 'planning' }" tag="li">
            {{ $t('nav_planning') }}
          </router-link>
          <router-link :to="{ name: 'stats' }" tag="li">
            {{ $t('nav_stats') }}
          </router-link>
          <router-link v-if="isLoggedIn()" :to="{ name: 'logout' }" tag="li">
            {{ $t('btn_logout') }}
          </router-link>
        </ul>
      </nav-bar>
    </div>
    <router-view/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
  components: {
    NavBar,
  },
  methods: {
    isLoggedIn() {
      return this.$auth.isLoggedIn;
    },
    appRoute() {
      return !['beta', 'landing', 'tos', 'privacy'].includes(this.$route.name);
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/_colors.scss';
@import '@/scss/_fonts.scss';
@import '@/scss/_reset.scss';
@import '@/scss/_base.scss';

#nav {
  position: relative;
  margin: 0;
  padding: 0;
  background: $color-primary;
}

.auth-form {
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.error {
  color: red;
}

.main-nav {
  display: flex;
  flex-direction: column;

  .nav-toggle {
    margin: 5px 5px 5px auto;
    background: transparent;
    color: $color-white;
    border: none;
    width: 100%;
    text-align: right;
    padding: 0 0.5em 0 0;
    cursor: pointer;
  }

  ul {
    background-color: $color-primary;
    margin: 0;
    padding: 0 0.5em 1em;
    list-style: none;
    @media only screen and (min-width: 800px) {
      padding: 5px 0 0 0;
      display: flex;
      box-shadow: inset 0 -7px 5px -5px
        change-color(scale-color($color-primary, $lightness: -10%), $alpha: 0.2);
    }

    li {
      margin: 2px 0;
      padding: 0.5em 1em;
      cursor: pointer;
      background-color: scale-color($color-light, $lightness: -5%, $saturation: -3%);
      @media only screen and (min-width: 800px) {
        margin: 0 2px;
        border: 1px solid $color-dark;
        border-bottom: none;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        box-shadow: inset 0 -4px 3px -3px
          change-color(scale-color($color-light, $lightness: -10%), $alpha: 0.4);
      }

      &.router-link-active {
        background-color: $color-light;
        box-shadow: none;
      }
    }

  }
}
</style>
