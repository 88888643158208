<template>
  <li class="planned-task">
    <div v-if="plannedTask" class="date">{{ plannedTask.date }}</div>
    <div v-if="plannedTask && plannedTask.date >= today" class="duration">
      <numeric-value-widget
        :value="plannedTask.duration"
        @input="handleDurationUpdate"
        :min="0"
        :increment="durationIncrement"
        :parser="parseDuration"
        :formatter="formatDuration" />
    </div>
    <div v-else>{{ duration }}</div>
    <div v-if="plannedTask">
      <slot :plannedTask="plannedTask" />
    </div>
  </li>
</template>

<script>
import NumericValueWidget from '@/components/NumericValueWidget.vue';
import PlannedTask from '@/core/models/PlannedTask';
import {
  MINUTE,
  durationToString,
  parseDuration,
  today,
} from '@/core/utils/dateUtils';

export default {
  components: {
    NumericValueWidget,
  },
  props: {
    plannedTask: {
      type: PlannedTask,
      required: true,
    },
  },
  data() {
    return {
      debounceId: null,
    };
  },
  computed: {
    durationIncrement() {
      return 10*MINUTE;
    },
    duration() {
      return durationToString(this.plannedTask.duration);
    },
    today() {
      return today();
    },
  },
  methods: {
    formatDuration: durationToString,
    parseDuration,
    handleDurationUpdate(newDuration) {
      this.plannedTask.duration = newDuration;
      clearTimeout(this.debounceId);
      this.debounceId = setTimeout(() => {
        this.$emit('update', this.plannedTask);
      }, 1200);
    },
  },
};
</script>

<style lang="scss" scoped>
.planned-task {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: 1px solid #aaa;
  padding: 0;
}
.duration {
  flex-grow: 0;
}
.date, .time {
  font-family: monospace;
  font-size: 10pt;
}
</style>
