var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    {
      staticClass: "label",
      attrs: { transform: "translate(" + _vm.x + ", " + _vm.y + ")" }
    },
    [
      _c("rect", {
        attrs: {
          x: -_vm.realWidth / 2,
          y: -_vm.height / 2,
          width: _vm.realWidth,
          height: _vm.height
        }
      }),
      _c(
        "text",
        {
          ref: "label",
          staticClass: "label",
          attrs: {
            "text-anchor": "middle",
            "dominant-baseline": "middle",
            x: "0",
            y: _vm.height * -0.2
          }
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _c(
        "text",
        {
          staticClass: "value",
          attrs: {
            "text-anchor": "middle",
            "dominant-baseline": "middle",
            x: "0",
            y: _vm.height * 0.2
          }
        },
        [_vm._v(_vm._s(_vm.value))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }