const toasts = [];
export default {
  install(Vue) {
    if (Vue.prototype.$getToasts) return; // already installed
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$getToasts = () => toasts;
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$toast = (level, message, options = null) => {
      toasts.push({ message, level, options });
    };
    // for service worker
    // eslint-disable-next-line no-param-reassign
    Vue.$toast = Vue.prototype.$toast;
  },
};
