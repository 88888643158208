import debug from '@/debug';

const PROVIDER = Symbol('Auth provider');
const LISTENERS = Symbol('Event listeners');
const PERSISTENCE = Symbol('persistence');

function mkKey(key) {
  return `f260.timefly.auth.${key}`;
}

function emit(event, ...args) {
  debug('AuthManager event', event, args);
  const promises = [];
  this[LISTENERS][event].forEach(cb => promises.push(cb(...args)));
  return Promise.all(promises);
}

async function processLoginAttempt(result) {
  debug('AuthManager.processLoginAttempt', result);
  if (result) {
    this[PERSISTENCE].setItem(mkKey('user'), result.name);
    this[PERSISTENCE].setItem(mkKey(`db:${result.name}`), result.db);
    await emit.call(this, 'login', result.name, result.db);
  } else {
    // login failed, emit logout
    const uName = this[PERSISTENCE].getItem(mkKey('user'));
    this[PERSISTENCE].removeItem(mkKey(`db:${uName}`));
    this[PERSISTENCE].removeItem(mkKey('user'));
    await emit.call(this, 'logout');
  }
  return result;
}

class AuthManager {
  /**
   * @param provider AuthProvider
   * @param persistance Storage-compatible object for keeping login state
   */
  constructor(provider, persistence) {
    this[PROVIDER] = provider;
    this[PERSISTENCE] = persistence;
    this[LISTENERS] = {
      register: new Map(),
      login: new Map(),
      logout: new Map(),
      'login-offline': new Map(),
    };
  }

  get isLoggedIn() {
    debug(`AuthManager.isLoggedIn(): ${!!this.loggedInUser}`);
    return !!this.loggedInUser;
  }

  set loggedInUser(username) {
    debug(`AuthManager.loggedInUser = ${username}`);
    this[PERSISTENCE].setItem(mkKey('user'), username);
  }

  get loggedInUser() {
    return this[PERSISTENCE].getItem(mkKey('user'));
  }

  get offlineDb() {
    return this[PERSISTENCE].getItem(mkKey('offlineDb'));
  }

  async userDb(user) {
    let db = this[PERSISTENCE].getItem(mkKey(`db:${user}`));
    if (!db) {
      ({ db } = await this[PROVIDER].getUserDatabase(user));
    }
    return db;
  }

  refresh() {
    const user = this.loggedInUser;
    debug('AuthManager.refresh', user);
    if (!user) {
      if (this.offlineDb) return emit.call(this, 'login-offline', this.offlineDb);
      return Promise.resolve();
    }
    return this[PROVIDER].isLoggedIn()
      .then(async (result) => {
        const db = await this.userDb(user);
        return { db, result };
      })
      .then(({ db, result }) => processLoginAttempt.call(
        this,
        result ? { name: user, db } : false,
      ))
      .catch((e) => { console.warn('Error processing login attempt (ignored)', e); });
  }

  hasEvent(event) {
    return Object.keys(this[LISTENERS]).includes(event);
  }

  on(event, key, callback) {
    if (!this.hasEvent(event)) {
      throw new Error(`Unknown event: ${event}`);
    }
    this[LISTENERS][event].set(key, callback);
  }

  off(event, key) {
    if (!this.hasEvent(event)) {
      throw new Error(`Unknown event: ${event}`);
    }
    this[LISTENERS][event].delete(key);
  }

  register(user, pass, key) {
    return this[PROVIDER].register(user, pass, key)
      .then(() => emit.call(this, 'register'));
  }

  /**
   * Uses auth provider to perform a login attempt,
   * calls login/logout events accordingly, and resolves with login result.
   * Lifecycle:
   * - call to the provider
   * - provider calls to database
   * - provider returns result or rejects with connection error
   *   * if resolved w/ 'true' - login success, emit 'login' events
   *   * if resolved w/ 'false' - login failed, emit 'logout' events
   *   * if rejected: unexpected error (most likely network error),
   *                  reject w/o events
   * - unless rejected: resolve with login result (true if login succeeded,
   *                    false otherwise)
   */
  login(user, pass) {
    return this[PROVIDER].login(user, pass)
      .then(result => processLoginAttempt.call(this, result));
  }

  logout() {
    return this[PROVIDER].logout()
      .then(() => {
        this[PERSISTENCE].removeItem(mkKey('user'));
        return emit.call(this, 'logout');
      });
  }

  startOfflineMode() {
    // FIXME: allow for multiple offliune databases
    const offlineDbName = 'Anonymous';
    this[PERSISTENCE].removeItem(mkKey('user'));
    this[PERSISTENCE].setItem(mkKey('offlineDb'), offlineDbName);
    return emit.call(this, 'login-offline', offlineDbName);
  }

  stopOfflineMode() {
    this[PERSISTENCE].removeItem(mkKey('offlineDb'));
  }
}

export default AuthManager;
