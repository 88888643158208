var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.appRoute()
        ? _c(
            "div",
            { attrs: { id: "nav" } },
            [
              _c(
                "nav-bar",
                { attrs: { "min-width": "800", addClasses: "main-nav" } },
                [
                  _c(
                    "ul",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: { name: "dashboard" },
                            tag: "li",
                            exact: ""
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("nav_dashboard")) + " ")]
                      ),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "planning" }, tag: "li" } },
                        [_vm._v(" " + _vm._s(_vm.$t("nav_planning")) + " ")]
                      ),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "stats" }, tag: "li" } },
                        [_vm._v(" " + _vm._s(_vm.$t("nav_stats")) + " ")]
                      ),
                      _vm.isLoggedIn()
                        ? _c(
                            "router-link",
                            { attrs: { to: { name: "logout" }, tag: "li" } },
                            [_vm._v(" " + _vm._s(_vm.$t("btn_logout")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }