<template>
  <li :class="containerClasses">
    <div v-if="showCategory" class="category-bar">
      <div class="category-bar-inner">
        <div
          class="category-name"
          v-for="(c, idx) in task.category"
          :key="categoryPartKey(task.category, idx)">
          {{ c }}
        </div>
      </div>
    </div>
    <div :class="taskClasses">
      <div class="own-content">
        <div class="flex clickable" @click="handleClick">
          <div class="task-name">{{ taskName }}</div>
          <div class="task-priority">{{ priorityStr }}</div>
        </div>
      </div>
      <div class="extra" v-if="$slots.default">
        <slot />
      </div>
    </div>
    <div class="task-children" v-if="childrenOpen">
      <time-entry-list
        :timeEntries="task.timeEntries"
        @edit="handleEdit"
        @delete="handleDelete" />
      <planned-task-entry-list
        :plannedTasks="task.plannedTasks"
        @update="handleUpdate"
        @edit="handleEdit"
        @delete="handleDelete" />
    </div>
  </li>
</template>

<script>
import TimeEntryList from '@/components/TimeEntryList.vue';
import PlannedTaskEntryList from '@/components/PlannedTaskEntryList.vue';
import { hue } from '@/core/utils/taskUtils';

export default {
  components: {
    TimeEntryList,
    PlannedTaskEntryList,
  },
  data() {
    return {
      childrenOpen: false,
    };
  },
  props: {
    task: {
      validator(value) {
        return (
          Array.isArray(value.category)
          && typeof value.name === 'string'
          && typeof value.priority === 'number'
        );
      },
      required: true,
    },
    showCategory: {
      type: Boolean,
      required: false,
      default: true,
    },
    topCategoryChanged: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    const mainCategory = this.task.category.length ? this.task.category[0] : '';
    this.$el.style['border-color'] = `hsl(${hue(mainCategory)}, 70%, 60%)`;
  },
  methods: {
    handleClick() {
      this.childrenOpen = !this.childrenOpen;
    },
    categoryPartKey(category, idx) {
      let key = 'task-category-';
      for (let i=0; i<=idx; i++) {
        key += category[i];
      }
      return key;
    },
    handleEdit(item) {
      this.$emit('edit', item);
    },
    handleDelete(item) {
      this.$emit('delete', item);
    },
    handleUpdate(item) {
      this.$emit('update', item);
    },
  },
  computed: {
    priorityStr() {
      return this.$t(`task_priority_${this.task.priority}`);
    },
    taskName() {
      return this.task.name;
    },
    taskClasses() {
      const classes = ['task-item'];
      if (this.task.done || this.task.task && this.task.task.done) classes.push('done');
      return classes;
    },
    containerClasses() {
      return this.topCategoryChanged ? ['top-category-change'] : [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_colors.scss';

li {
  border-left: 5px solid silver;
  margin: 0 1em;
  padding: 0.5em 0.5em;
}

li.top-category-change {
  margin-top: 5px;
  padding-top: 0;
}

div {
  text-align: left;
}
.task-item {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: left;
  border-bottom: 1px dotted #ccc;
  margin: 2px 0;

  &.done {
    background-color: change-color($color-done, $alpha: 0.3);
  }
}
.own-content {
  width: 100%;
}
.flex {
  display: flex;
  justify-content: space-between
}
.task-name {
  flex-grow: 1;
}
.task-priority {
  border-left: 1px dotted gray($color-light, -20%);
  margin-left: 3px;
  padding-left: 1em;
  margin-right: 3px;
  flex-grow: 0;
}
.category-bar {
  margin-top: 1em;
  margin-bottom: 0.2em;
  padding: 0;
  border-bottom: 1px solid #ccc;
  display: flex;
}
.category-bar-inner {
  background: #eeeeee;
  padding: 0;
}
.category-name {
  margin: 2px;
  font-size: 8pt;
  border-radius: 5px;
  padding: 0 0.2em;
  display: inline-block;
}
.category-name::after {
  content: '\00bb';
}
.category-name:last-child::after {
  content: '';
}
.extra {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.clickable {
  cursor: pointer;
}

.task-children {
  margin-left: 2em;
}
</style>
