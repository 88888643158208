<template>
  <task-list-base
    v-if="tasks"
    :tasks="tasks"
    :includeCompleted="includeCompleted"
    @update="handleUpdate"
    @edit="handleEdit"
    @delete="handleDelete" >

    <template v-slot:default="slot">
      <button class="task-button" @click="handleEdit(slot.task)">
        <font-awesome-icon icon="edit" />
      </button>
      <button class="task-button" @click="handleAddPlanned(slot.task)">
        <font-awesome-icon :icon="['far', 'calendar-plus']" />
      </button>
      <button class="task-button delete" @click="handleDelete(slot.task)">
        <font-awesome-icon :icon="['fas', 'trash-alt']" />
      </button>
      <button class="task-button done" @click="handleDone(slot.task)">
        <font-awesome-icon :icon="['far', 'check-square']" />
      </button>
      <button v-if="isRunning(slot.task)" class="task-button" @click="handleStop(slot.task)">
        <font-awesome-icon :icon="['fas', 'stop']" />
      </button>
      <button v-else class="task-button" @click="handleStart(slot.task)">
        <font-awesome-icon :icon="['fas', 'play']" />
      </button>
    </template>
  </task-list-base>
  <img v-else :src="loader" alt="$t('loading')" />
</template>

<script>
import TaskListBase from '@/components/TaskListBase.vue';

export default {
  name: 'task-list',
  components: {
    TaskListBase,
  },
  props: {
    tasks: {
      required: true,
    },
    loader: {
      type: String,
      required: true,
    },
    includeCompleted: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleEdit(task) {
      this.$emit('edit', task);
    },
    handleDelete(task) {
      this.$emit('delete', task);
    },
    handleUpdate(item) {
      this.$emit('update', item);
    },
    handleAddPlanned(task) {
      const plannedTask = this.$manager.plan(task);
      this.$emit('update', plannedTask);
    },
    handleDone(task) {
      this.$emit('done', task);
    },
    handleStart(task) {
      this.$emit('start', task);
    },
    handleStop(task) {
      this.$emit('stop', task);
    },
    isRunning(task) {
      return task.timeEntries.some(te => te.start && !te.end);
    },
  },
};
</script>
