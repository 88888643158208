function newData(name, start, level, data) {
  const d = {
    name,
    level,
    start,
    end: start,
    total: 0,
  };
  data.push(d);
  return d;
}

function taskTime(task, dates) {
  return task.timeEntries.reduce((acc, te) => {
    if (te.start < dates[0] || te.start >= dates[1]) return acc;
    const end = te.end ? te.end.getTime() : Date.now();
    return acc + end - te.start.getTime();
  }, 0);
}

function finalizeCategories(lastCategory, data, changeLevel) {
  for (let i=lastCategory.length-1; i>=changeLevel; i--) {
    // eslint-disable-next-line no-param-reassign
    lastCategory[i].end = lastCategory[i].start + lastCategory[i].total;
    // eslint-disable-next-line no-param-reassign
    lastCategory.length--;
  }
}

function addNewCategories(lastCategory, task, start, data) {
  for (let i=lastCategory.length; i<task.category.length; i++) {
    const category = newData(task.category.slice(0, i+1), start, i, data);
    lastCategory.push(category);
  }
}

function appendNoramlCategoryTask(lastCategory, data, task, dates) {
  const time = taskTime(task, dates);
  if (time === 0) return;
  const startFrom = lastCategory.length ? lastCategory[0].start + lastCategory[0].total : 0;
  for (let i=0; i<task.category.length; i++) {
    if (lastCategory.length <= i) {
      // new sub-category
      addNewCategories(lastCategory, task, startFrom, data);
    } else if (lastCategory[i].name[i] !== task.category[i]) {
      // category changed!
      finalizeCategories(lastCategory, data, i);
      addNewCategories(lastCategory, task, startFrom, data);
    }
    // eslint-disable-next-line no-param-reassign
    lastCategory[i].total += time;
  }
  if (task.category.length < lastCategory.length) {
    // out of sub-category, category changed!
    finalizeCategories(lastCategory, data, task.category.length);
  }
  const taskData = newData([...task.category, task.name], startFrom, lastCategory.length, data);
  taskData.total = time;
  taskData.end = startFrom + time;
}

function appendTask(lastCategory, data, task, dates) {
  if (task.timeEntries.length === 0) return;
  if (task.category.length === 0) {
    appendNoramlCategoryTask(lastCategory, data, { ...task, category: [''] }, dates);
  } else {
    appendNoramlCategoryTask(lastCategory, data, task, dates);
  }
}

export default function taskStatData(tasks, dates) {
  const lastCategory = [];
  const data = [];
  for (let i=0; i<tasks.length; i++) {
    appendTask(lastCategory, data, tasks[i], dates);
  }
  if (lastCategory.length) finalizeCategories(lastCategory, data, 0);
  return data;
}
