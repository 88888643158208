class TaskDAO {
  constructor(data) {
    Object.defineProperty(this, 'is', {
      enumerable: true,
      writable: false,
      configurable: false,
      value: 'task',
    });
    this._id = data._id;
    this.name = data.name;
    this.category = data.category;
    this.priority = data.priority;
    this.tags = data.tags;
    this.done = data.done;
    if (data.debug) {
      this.debug = data.debug;
    }
  }
}

export default TaskDAO;
