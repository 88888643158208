var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.cancel($event)
        },
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)
          ) {
            return null
          }
          return _vm.cancel($event)
        }
      }
    },
    [
      _c("div", { staticClass: "modal-window" }, [
        _c(
          "label",
          { attrs: { for: "time-entry-start" } },
          [
            _vm._v(_vm._s(_vm.$t("te_start")) + " "),
            _c("time-input", {
              attrs: { id: "time-entry-start" },
              model: {
                value: _vm.timeEntry.start,
                callback: function($$v) {
                  _vm.$set(_vm.timeEntry, "start", $$v)
                },
                expression: "timeEntry.start"
              }
            })
          ],
          1
        ),
        _c(
          "label",
          { attrs: { for: "time-entry-end" } },
          [
            _vm._v(_vm._s(_vm.$t("te_end")) + " "),
            _c("time-input", {
              attrs: { id: "time-entry-end" },
              model: {
                value: _vm.timeEntry.end,
                callback: function($$v) {
                  _vm.$set(_vm.timeEntry, "end", $$v)
                },
                expression: "timeEntry.end"
              }
            })
          ],
          1
        ),
        _c("button", { staticClass: "save", on: { click: _vm.save } }, [
          _vm._v(_vm._s(_vm.$t("save")))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }