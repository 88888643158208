<template>
  <div class="quick-add-wrapper">
    <form
      :id="id('quick-add-task')"
      class="quick-add-task"
      @keydown="formKeyDown"
      @keyup="formKeyUp"
      @submit.prevent="() => {}"
    >
      <div>
        <input
          autocomplete="off"
          class="task-name-input"
          :id="id('new-task-name')"
          :placeholder="$t('task_name')"
          @focus="showSuggestions"
          @blur="hideSuggestions"
          @keyup.down.prevent="onDown"
          @keyup.up.prevent="onUp"
          @keyup.enter="onEnter"
          v-model="name"/>
      </div>
      <tag-selector
        :id="id('new-task-category')"
        :placeholder="$t('task_category')"
        :suggest="suggestCategory"
        v-model="category" />
      <div>
        <select v-model="priority">
          <option
            v-for="p in new Set(Object.values(priorities))"
            :key="`option-priority-${p}`"
            :value="p" >
            {{ $t(`task_priority_${p}`) }}
          </option>
        </select>
      </div>
      <div>
        <button
          type="button"
          class="task-button square30"
          :id="id('add-task')"
          @click="defaultAction" >
          <font-awesome-icon v-if="forceStart || (autostart && !forceAdd)" icon="play" />
          <font-awesome-icon v-else icon="plus-square" />
        </button>
      </div>
      <label for="auto-start-checkbox">
        <input
          type="checkbox"
          v-model="autostart"
          name="auto-start-task"
          id="auto-start-checkbox"
        />
        {{ $t('auto_start_checkbox') }}
      </label>
    </form>
    <suggestion-box
      ref="suggestionBox"
      @keyup.enter.prevent="() => {}"
      @select="startExisting"
      @addTask="handleAddTask"
      :search="name"
      :show="suggestions"
      />
  </div>
</template>

<script>
import TagSelector from '@/components/TagSelector.vue';
import SuggestionBox from '@/components/SuggestionBox.vue';

import guid from '@/mixins/guid';
import { priority as priorities } from '@/core/constants';
import { arrayStartsWith } from '@/core/utils/arrayUtils';

export default {
  mixins: [guid],
  components: {
    TagSelector,
    SuggestionBox,
  },
  props: {
    categories: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      name: '',
      category: [],
      priority: priorities.DEFAULT,
      priorities,
      suggestions: false,
      autostart: true,
      forceStart: false,
      forceAdd: false,
    };
  },
  methods: {
    showSuggestions() {
      this.suggestions = true;
    },
    hideSuggestions() {
      this.suggestions = false;
    },
    suggestCategory(input) {
      return this.categories
        .filter(c => arrayStartsWith(c.map(cpart => cpart.toLowerCase()),
          this.category.map(cpart => cpart.toLowerCase()))
            && new RegExp(`^${input}`, 'ui').test(c[this.category.length]))
        .map(suggestion => suggestion[this.category.length])
        .reduce((acc, v) => acc.add(v), new Set());
    },
    clearForm() {
      this.name = '';
      this.category = [];
      this.priority = priorities.DEFAULT;
    },
    formKeyDown(ev) {
      if (ev.code === 'ShiftLeft' || ev.code === 'ShiftRight' || ev.keyCode === 0x10) {
        if (!this.forceStart) this.forceAdd = true;
      } else if (ev.code === 'ControlLeft' || ev.code === 'ControlRight' || ev.keyCode === 0x11) {
        if (!this.forceAdd) this.forceStart = true;
      }
    },
    formKeyUp(ev) {
      if (ev.code === 'ShiftLeft' || ev.code === 'ShiftRight' || ev.keyCode === 0x10) {
        this.forceAdd = false;
      } else if (ev.code === 'ControlLeft' || ev.code === 'ControlRight' || ev.keyCode === 0x11) {
        this.forceStart = false;
      }
      if (ev.code === 'Enter' || ev.keyCode === 0x0d) {
        this.defaultAction();
      }
    },
    defaultAction() {
      if ((this.autostart && !this.forceAdd) || this.forceStart) {
        this.startTask();
      } else {
        this.addTask();
      }
    },
    addTask() {
      const task = this.$manager.newTask({
        name: this.name,
        category: this.category,
        priority: this.priority,
      });
      this.$manager.save(task);
      this.clearForm();
    },
    startTask() {
      this.$emit('start-new', {
        name: this.name,
        category: this.category,
        priority: this.priority,
      });
      this.clearForm();
    },
    handleAddTask(callback) {
      this.$emit('add-task', callback);
    },
    startExisting(task) {
      this.$emit('start-task', task);
      this.clearForm();
    },
    onDown() {
      this.$refs.suggestionBox.next();
    },
    onUp() {
      this.$refs.suggestionBox.prev();
    },
    onEnter(ev) {
      if (this.$refs.suggestionBox.selectedTaskIdx !== null
        && this.$refs.suggestionBox.selectedTaskIdx !== undefined
      ) {
        this.$refs.suggestionBox.select();
        ev.stopPropagation();
        ev.preventDefault();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.quick-add-task {
  display: grid;
  grid-template-rows: auto auto auto auto;
  justify-items: space-between;
  align-items: center;
  padding: 3px;

  &>* {
    padding: 3px 0;
  }

  &>select {
    padding-right: 20px;
  }
}
.task-name-input {
  width: 100%;
}
@media only screen and (min-width: 550px) and (max-width: 800px),
screen and (min-width: 1100px) {
  .quick-add-task {
    grid-template-columns: 0.6fr 0.4fr auto 35px;
    padding: 0 3px;

    &>* {
      padding: 0 3px;
    }

    &>select {
      padding-right: 20px;
    }
  }
}
</style>
