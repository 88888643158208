<template>
  <li class="time-entry">
    <div v-if="timeEntry" class="date">{{ timeEntry.start }}</div>
    <div v-if="timeEntry" class="time">{{ time }}</div>
    <div v-if="timeEntry" class="running">
      <font-awesome-icon v-if="!timeEntry.end" :icon="['far', 'clock']" class="blinking" />
      <slot :timeEntry="timeEntry" />
    </div>
  </li>
</template>

<script>
import TimeEntry from '@/core/models/TimeEntry';

function zeroPad(v) {
  const strV = v.toString();
  switch (strV.length) {
    case 0:
      return '00';
    case 1:
      return `0${strV}`;
    default:
      return strV;
  }
}

function formatTime(time) {
  const hours = Math.floor(time / 3600000);
  const minutes = Math.floor(time / 60000 - hours * 60);
  const seconds = Math.floor(time / 1000 - minutes*60 - hours*3600);
  return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
}

export default {
  props: {
    timeEntry: {
      type: TimeEntry,
      required: true,
    },
  },
  data() {
    return {
      now: new Date(),
    };
  },
  computed: {
    time() {
      const end = this.timeEntry.end || this.now;
      return formatTime(end - this.timeEntry.start);
    },
  },
  mounted() {
    if (!this.timeEntry.end) {
      this.$timer.start('updateTime');
    }
  },
  timers: {
    updateTime: {
      time: 100,
      autostart: false,
      repeat: true,
      immediate: true,
    },
  },
  methods: {
    updateTime() {
      this.now = new Date();
      if (this.timeEntry.end) {
        this.$timer.stop('updateTime');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.time-entry {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: 1px solid #aaa;
  padding: 0;
}
.time {
  flex-grow: 0;
}
.date, .time {
  font-family: monospace;
  font-size: 10pt;
}
.blinking {
  animation: blink 500ms ease-in-out infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
