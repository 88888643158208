import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretLeft,
  faCaretRight,
  faChevronRight,
  faChevronDown,
  faFolderPlus,
  faPlusSquare,
  faEdit,
  faBars,
  faArrowsAlt,
  faPlay,
  faStop,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendarCheck,
  faCalendarPlus,
  faCheckSquare,
  faClock,
} from '@fortawesome/free-regular-svg-icons';

import * as Keycloak from 'keycloak-js';
import initApp from './initVue';
import debug from '@/debug';

library.add(
  faCaretLeft,
  faCaretRight,
  faChevronRight,
  faChevronDown,
  faFolderPlus,
  faPlusSquare,
  faEdit,
  faBars,
  faArrowsAlt,
  faPlay,
  faStop,
  faCalendarCheck,
  faCalendarPlus,
  faTrashAlt,
  faCheckSquare,
  faClock,
);

const initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: 'timefly',
  clientId: 'timefly-web',
  onLoad: 'login-required',
};
const keycloak = Keycloak(initOptions);

// FIXME: remove after testing
/* eslint-disable no-console */

keycloak.init({
  flow: 'hybrid',
  checkLoginIframe: true,
  onload: initOptions.onLoad,
}).then((auth) => {
  if (!auth) {
    debug('Trying to log in...');
    keycloak.login();
  } else {
    debug('Initializing vue app...');
    initApp(keycloak);
  }

  setInterval(() => {
    debug('refreshing token...');
    keycloak.updateToken(600).then((refreshed) => {
      if (refreshed) {
        debug('token refreshed');
        // token refreshed
      } else {
        const expiresIn = keycloak.tokenParsed.exp
          + keycloak.timeSkew
          - (Date.now() / 1000);
        console.warn(`Failed to refresh token, valid for ${expiresIn} seconds`);
      }
    }).catch((e) => {
      const expiresIn = keycloak.tokenParsed.exp
        + keycloak.timeSkew
        - (Date.now() / 1000);
      console.warn(`Failed to refresh token, valid for ${expiresIn} seconds: `, e);
    });
  }, 300000);
}).catch((e) => { console.error('Authentication failed: ', e); });
