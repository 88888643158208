var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-container",
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.handleNo($event)
        },
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)
          ) {
            return null
          }
          return _vm.handleNo($event)
        }
      }
    },
    [
      _c("div", { staticClass: "modal-window" }, [
        _c("p", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))]),
        _c(
          "button",
          {
            staticClass: "yes",
            attrs: { id: "confirm-button-yes" },
            on: { click: _vm.handleYes }
          },
          [_vm._v(_vm._s(_vm.yesText || _vm.defaultYesText))]
        ),
        _c(
          "button",
          {
            staticClass: "no",
            attrs: { id: "confirm-button-no" },
            on: { click: _vm.handleNo }
          },
          [_vm._v(_vm._s(_vm.noText || _vm.defaultNoText))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }