import AuthManager from '@/core/managers/AuthManager';

const authManagerPlugin = {
  install(Vue, options) {
    if (Vue.prototype.$auth) {
      console.error('$auth already defined', Vue.prototype.$auth);
      return; // prevent accidental double-install
    }
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$auth = new AuthManager(options, localStorage);
    // eslint-disable-next-line no-param-reassign
    Vue.$auth = Vue.prototype.$auth; // for router
  },
};

export default authManagerPlugin;
