<template>
  <g v-if="this.tasks" :transform="`translate(${radius}, ${radius})`">
    <path
      v-for="datum in chartData"
      :fill="datum.fill"
      :d="datum.d"
      :key="keyFor(datum, 'path')"
      @mouseenter="select(datum)"
      @mouseleave="deselect(datum)"
      @click="toggle(datum)"
      />
    <path
      v-if="selected"
      class="selected"
      :fill="selectedDatum.fill"
      :d="selectedDatum.d" />
    <stat-chart-label v-if="selected" v-bind="selectedDatum" />
  </g>
</template>

<script>
import { arc } from 'd3-shape';

import StatChartLabel from '@/components/StatChartLabel.vue';
import { arrayEquals } from '@/core/utils/arrayUtils';
import { durationToString } from '@/core/utils/dateUtils';
import { hue } from '@/core/utils/taskUtils';

export default {
  components: {
    StatChartLabel,
  },
  props: {
    keyPrefix: {
      type: String,
      default: 'swc-',
    },
    radius: {
      type: Number,
      default: 100,
    },
    level: {
      type: Number,
      default: 0,
    },
    tasks: Array,
    selected: {
      default: null,
    },
    sticky: {
      default: false,
    },
  },
  methods: {
    keyFor(datum, prefix) {
      return this.keyPrefix + prefix + datum.name.join('\u00bb');
    },
    ringSize(i) {
      return this.ringScaler * Math.sqrt(i);
    },
    select(datum, sticky) {
      this.$emit('select', datum.name, sticky);
    },
    deselect(datum, sticky) {
      this.$emit('deselect', datum.name, sticky);
    },
    toggle(datum) {
      if (this.selected && this.sticky) {
        this.deselect(datum, true);
      } else {
        this.select(datum, true);
      }
    },
  },
  computed: {
    depth() {
      return Math.max(...this.tasks.map(t => t.name.length)) + 1;
    },
    ringScaler() {
      return this.radius / Math.sqrt(this.depth);
    },
    chartData() {
      const data = [];
      const total = this.tasks.reduce((acc, d) => (d.level === 0 ? acc + d.total : acc), 0);
      for (let i=0; i<this.tasks.length; i++) {
        data[i] = { ...this.tasks[i] };
        if (!data[i].category || data[i].category.length === 0) {
          data[i].category = [''];
        }
        data[i].startAngle = data[i].start / total * Math.PI*2;
        data[i].endAngle = data[i].end / total * Math.PI*2;
        data[i].innerRadius = this.ringSize(data[i].level+1);
        data[i].outerRadius = this.ringSize(data[i].level+2);
        data[i].fill = `hsl(${hue(data[i].name[0])}, 70%, ${50+data[i].level*(50/this.depth+1)}%)`;
        data[i].d = arc()(data[i]);
      }
      const totalData = {
        name: ['Total'],
        level: 0,
        start: 0,
        end: total,
        total,
        startAngle: 0,
        endAngle: Math.PI*2,
        innerRadius: 0,
        outerRadius: this.ringSize(1),
        fill: 'rgba(0,0,0,0)',
      };
      totalData.d = arc()(totalData);
      data.push(totalData);
      return data;
    },
    selectedDatum() {
      if (!this.selected) return null;
      const datum = this.chartData.find(d => arrayEquals(d.name, this.selected));
      const [x, y] = arc().centroid(datum);
      return {
        name: datum.name,
        label: datum.name[datum.name.length-1],
        value: durationToString(datum.total),
        x,
        y,
        d: datum.d,
        fill: datum.fill,
      };
    },
  },
};
</script>

<style>
g {
  stroke: white;
  stroke-width: 2px;
}
.selected {
  stroke: red;
  pointer-events: none;
}
</style>
