<template>
  <task-list-base
    v-if="tasks"
    :tasks="tasks"
    :includeCompleted="includeCompleted"
    @action="handleEdit">

    <template v-slot:default="slot">
      <button
        v-if="shouldShowStartButton(slot.task)"
        class="task-button start"
        @click="handleStart(slot.task)"
      >
        <font-awesome-icon :icon="['fas', 'play']" size="lg" />
      </button>
      <button
        v-else-if="shouldShowStopButton(slot.task)"
        class="task-button stop"
        @click="handleStop(slot.task)"
      >
        <font-awesome-icon :icon="['fas', 'stop']" size="lg" />
      </button>
      <div>
        <numeric-value-widget
          :value="slot.task.duration"
          @input="newDuration => handleDurationUpdate(slot.task, newDuration)"
          :min="0"
          :increment="durationIncrement"
          :parser="parseDuration"
          :formatter="formatDuration"
          extraButtonClasses="task-button"
          />
        <button class="task-button" @click="handleEdit(slot.task)">
          <font-awesome-icon icon="edit" />
        </button>
        <button class="task-button delete" @click="handleDelete(slot.task)">
          <font-awesome-icon :icon="['fas', 'trash-alt']" />
        </button>
        <button class="task-button done" @click="handleDone(slot.task)">
          <font-awesome-icon :icon="['far', 'check-square']" />
        </button>
      </div>
    </template>
  </task-list-base>
  <img v-else :src="loader" :alt="$t('loading')" />
</template>

<script>
import { MINUTE, durationToString, parseDuration } from '@/core/utils/dateUtils';
import TaskListBase from '@/components/TaskListBase.vue';
import NumericValueWidget from '@/components/NumericValueWidget.vue';

export default {
  name: 'task-list-planned',
  components: {
    NumericValueWidget,
    TaskListBase,
  },
  props: {
    hasStartButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    tasks: {
      required: true,
    },
    loader: {
      type: String,
      required: true,
    },
    includeCompleted: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debounceId: null,
    };
  },
  computed: {
    durationIncrement() {
      return 10*MINUTE;
    },
  },
  methods: {
    handleEdit(task) {
      this.$emit('edit', task);
    },
    handleDelete(task) {
      this.$emit('delete', task);
    },
    handleDurationUpdate(task, newDuration) {
      // eslint-disable-next-line no-param-reassign
      task.duration = newDuration;
      clearTimeout(this.debounceId);
      this.debounceId = setTimeout(() => {
        this.$emit('update', task);
      }, 1200);
    },
    handleStart(plannedTask) {
      this.$emit('start', plannedTask.task);
    },
    handleStop(plannedTask) {
      this.$emit('stop', plannedTask.task);
    },
    handleDone(task) {
      this.$emit('done', task);
    },

    shouldShowStartButton(plannedTask) {
      return this.hasStartButton && !plannedTask.task.isRunning;
    },
    shouldShowStopButton(plannedTask) {
      return this.hasStartButton && plannedTask.task.isRunning;
    },
    formatDuration(ms) {
      return durationToString(ms);
    },
    parseDuration,
  },
};
</script>

<style lang="scss" scoped>
.task-button.start
.task-button.stop {
  height: 100%;
}
</style>
